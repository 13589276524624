import React, { Component } from "react";
import {
    Backdrop, Box,
    CircularProgress, Typography
} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent,
    DialogFormEditBase,
} from "./components";
import {
    DialogConfirmation,
    DialogExecutingOrders
} from "../../../../components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import queryString from "query-string";
import agent from "../../../../agent/agent";

const initialFilter = {
    "sort": ""
};
const urlPage = "/car-bases";

class CarBasesList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],

            editBase: {},

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            isCreateBase: false,
            isOpenFormBaseEdit: false,
            isLoading: true,
            isShowBackdrop: false
        };

        this.refDialogConfirmation = React.createRef();
        this.refDialogExecutingOrders = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getData();
    }

    getData = async () => {
        this.setState({isLoading: true});

        const filter = this.getFilters();
        const {
            depots,
            totalCount
        } = await agent.get(`/depots${filter}&limit=20&listing=true`).then((res) => {
            return {
                depots: res.data.depots || [],
                totalCount: res.data.count
            }
        }).catch((err) => {
            return {
                depots: [],
                totalCount: 0
            }
        });


        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(totalCount / 20) || 1;

        this.setState({
            isLoading: false,
            pagination,
            data: depots
        });
    }

    // Логика работы с фильтром
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `${urlPage}?${string.join("&")}`);

        return `?${string.join("&")}`
    }
    changeFilter = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getData();

    }
    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getOrders();
    }
    resetFilter = async () => {
        await this.setState({filter: {...initialFilter}});
        await this.getData();
    }

    // Логика создание базы
    createBase = async (form, isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: "Вы действительно хотите создать базу?",
                acceptButtonTitle: "Да, создать",
                cancelButtonTitle: "Отменить",
                acceptButtonAction: this.createBase.bind(this, form, true),
            })

            return null;
        }

        this.setState({isShowBackdrop: true})

        const response = await agent.post(`/depots`, form).then((res) => {
            return res.data
        }).catch((err) => {
            return {error: err.response}
        });
        if (response.error) {
            this.setState({isShowBackdrop: false})

            Notification({
                type: NotificationTypes.error,
                message: "Ошибка создания базы",
            });

            return null;
        }

        await this.getData();

        this.setState({isShowBackdrop: false})

        Notification({
            type: NotificationTypes.success,
            message: "База успешно создана"
        });
    }
    // Логика редактирования базы
    editBase = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: "Вы действительно хотите изменить информацию о базе?",
                acceptButtonTitle: "Да, изменить",
                cancelButtonTitle: "Отменить",
                acceptButtonAction: this.editBase.bind(this, form, true),
            })

            return null;
        }

        const body = {
            name: form.name,
            description: form.description,
            address: form.address,
            addressLat: form.addressLat,
            addressLong: form.addressLong,
        };
        this.setState({
            isShowBackdrop: true,
        });

        const response = await agent.put(`/depots/${form.id}`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });
        if (response.error) {
            this.setState({isShowBackdrop: false})

            Notification({
                type: NotificationTypes.error,
                message: "Ошибка редактирования базы",
            });

            return null;
        }

        this.setState({isShowBackdrop: false})
        await this.getData();

        Notification({
            type: NotificationTypes.success,
            message: "Информация о базе успешно изменена",
        });
    };
    // Логика удаления базы
    deleteBase = async (baseId, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: "Вы действительно хотите удалить автобазу?",
                acceptButtonTitle: "Да, удалить",
                cancelButtonTitle: "Отменить",
                acceptButtonAction: this.deleteBase.bind(this, baseId, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.delete(`/depots/${baseId}`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при удалении автобазы",
                type: NotificationTypes.error,
            });

            return null;
        }

        if (!response.success) {
            this.setState({isShowBackdrop: false});

            this.refDialogExecutingOrders.current.open({
                orders: response?.orders || [],
            });

            Notification({
                message: "Автобазу нельзя удалить, на автобазе есть заказы",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getData();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Автобаза успешно удалена",
            type: NotificationTypes.success,
        });
    };

    //Открытие формы редактирования базы
    setIsOpenFormEditBase = (isOpen, isCreate = false, editBase = {}) => {
        this.setState({
            isOpenFormEditBase: isOpen,
            isCreateBase: isCreate,
            editBase: editBase,
        })
    };
    //Закрытие формы редактирования базы
    handleCloseFormEditBase = () => {
        this.setIsOpenFormEditBase(false, false, {});
    }

    render() {
        const {
            data,

            editBase,
            filter,
            pagination,

            isCreateBase,
            isOpenFormEditBase,
            isLoading,
            isShowBackdrop
        } = this.state;

        return (
            <>

                <Box mb={4}>
                    <Typography variant="h1" textAlign="left">Базы</Typography>
                </Box>

                <FilterComponent
                    filter={filter}

                    onOpenFormCreateBase={this.setIsOpenFormEditBase}
                />

                <TableComponent
                    data={data}
                    filter={filter}
                    pagination={pagination}

                    isLoading={isLoading}

                    onChangePagination={this.changePagination}
                    onOpenFormEdit={this.setIsOpenFormEditBase}
                    onDeleteCarBase={this.deleteBase}
                />


                <DialogFormEditBase
                    base={editBase}
                    isOpen={isOpenFormEditBase}
                    isCreate={isCreateBase}
                    onClose={this.handleCloseFormEditBase}
                    onEdit={this.editBase}
                    onCreate={this.createBase}
                />

                <DialogConfirmation ref={this.refDialogConfirmation}/>

                <DialogExecutingOrders ref={this.refDialogExecutingOrders}/>

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="white"/>
                </Backdrop>
            </>
        );
    }
}

export default CarBasesList
