const formatErrors = (errors) => {
    const errorsArr = []
    errors.forEach((err) => {
        err?.constraints?.matches && errorsArr.push(err.constraints.matches)
    })
    return errorsArr
}

export {
    formatErrors
}