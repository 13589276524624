import React, { Component } from "react";
import { Backdrop, Box, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import {
    ActionForm,
    AdminsPhones as AdminsPhonesComponent,
    DialogAcceptOrder as DialogAcceptOrderComponent,
    DialogAddBillOfLadingPhotos as DialogAddBillOfLadingPhotosComponent,
    DialogFormDeliveredToClient,
    DialogFullReturnFill as DialogFullReturnFillComponent,
    DialogPartialReturnFill as DialogPartialReturnFillComponent,
    DialogReturnConfirm as DialogReturnConfirmComponent,
    DialogTransferReturnToProvider as DialogTransferReturnToProviderComponent,
    DialogTransferTheGoodsConfirm as DialogTransferTheGoodsConfirmComponent,
    InformationAddresses,
    InformationProducts,
    LifeCycle as LifeCycleComponent,
    OrderPhotos as OrderPhotosComponent,
    ReadyOrderInformation as ReadyOrderInformationComponent,
} from "./components";
import { withStyles } from "@mui/styles";
import { Notification, NotificationTypes } from "../../../../common/Notification";
import { DialogConfirmation } from "../../../../components";
import agent from "../../../../agent/agent";
import { userIsDriver } from "../../../../helper/userType";

class MyOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: {},
            returnItems: [],
            shortageItems: [],
            billOfLadings: [],

            orderId: props?.match?.params?.id,

            providerPhotos: [],
            driverPhotos: [],
            returnPhotos: [],
            billOfLadingPhotos: [],

            settings: {},

            adminsPhones: [],

            isOpenTransferTheGoodsConfirm: false,
            isOpenDeliveredToClient: false,
            isOpenAcceptOrder: false,
            isOpenPartialReturnFill: false,
            isOpenFullReturnFill: false,
            isOpenTransferReturnToProvider: false,
            isOpenReturnConfirm: false,
            isOpenAddBillOfLadingPhotos: false,

            isLoading: true,
            isLoadingProviderPhotos: false,
            isLoadingDriverPhotos: false,
            isLoadingReturnPhotos: false,
            isLoadingBillOfLadingPhotos: false,
            isLoadingBillOfLading: false,
            isLoadingSendLinkToAccount: false,

            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.getAdminPhones();
        await this.getSettings();
        await this.getReturnItems();
        await this.getShortageItems();
        await this.getOrder();

        if (this.state.order.deliveryStatus === 'courierInstalled') {
            Notification({
                message: "Вас выбрали в качестве экспедитора на данный заказ. Установите водителя",
                type: NotificationTypes.info,
            });

        }
    }

    // Получение заказа
    getOrder = async () => {

        const orderId = this.state.orderId;

        const order = await agent.get(`/orders/${ orderId }`).then((res) => {
            return res.data?.order || {}
        }).catch(() => {
            return {}
        });

        this.setState({
            order,
            isLoading: false,
        })
    }

    getSettings = async () => {
        const settings = await agent.get(`/settings`)
            .then((res) => res.data.settings)
            .catch((err) => {
            });

        this.setState({ settings });
    }

    // Получение возвращаемых товаров
    getReturnItems = async () => {
        const response = await agent.get(`/returns/${ this.state.orderId }/items`)
            .then(res => res.data.returnItems)
            .catch(err => []);

        this.setState({
            returnItems: response,
        });
    };

    // Получение невозврат товаров
    getShortageItems = async () => {
        const response = await agent.get(`/returns/${ this.state.orderId }/shortage-items`)
            .then(res => res.data.shortageItems)
            .catch(err => {
                return [];
            });

        this.setState({
            shortageItems: response,
        });
    };

    // Получение телефонов администраторов
    getAdminPhones = async () => {
        const response = await agent.get(`users/admins-phones`)
            .then(res => res.data.phones)
            .catch(err => []);

        this.setState({
            adminsPhones: response,
        });
    };

    // Логика получения накладной
    getBillOfLading = async () => {
        if (this.state.billOfLadings.length <= 0) {
            this.setState({ isLoadingBillOfLading: true });

            const response = await agent.get(`orders/${ this.state.orderId }/bill-of-lading`)
                .then(res => res.data.pdfFile)
                .catch(err => {
                    return { error: err.response };
                });

            if (response.error) {
                this.setState({
                    isLoadingBillOfLading: false,
                    billOfLadings: [],
                });

                Notification({
                    message: response.error?.data?.message || "Ошибка при загрузке накладных",
                    type: NotificationTypes.error,
                });

                return null;
            }

            let billOfLadingsUrls = [];

            if (Object.keys(response).length <= 0) return null;

            const billOfLadingUrl = window.URL.createObjectURL(new Blob([ new Uint8Array(response.data).buffer ]));

            billOfLadingsUrls.push(billOfLadingUrl);

            this.setState({
                isLoadingBillOfLading: false,
                billOfLadings: billOfLadingsUrls,
            });
        }
    };

    // Логика получения фотографий прикрепленных к заказу
    // TYPES - 'providerPhotos' | 'driverPhotos' | 'returnPhotos' | 'billOfLadingPhotos'
    getOrderPhotosUsingType = async (type = '', callback) => {
        this.setLoadingStatusOrderPhotos(type, true);

        const response = await agent.get(`/orders/${ this.state.orderId }/photos?neededType=${ type }`)
            .then(res => res.data.photos)
            .catch(err => {
                return { error: err.response }
            });

        if (response.error) {
            this.setLoadingStatusOrderPhotos(type, false);

            Notification({
                message: response.error?.data?.message || "Ошибка при загрузке фотографий",
                type: NotificationTypes.error,
            });

            return null;
        }

        let newPhotos = [];

        if (response.length > 0) {
            if (Array.isArray(response) && response.length > 0) {
                newPhotos = [ ...response.map(receipt => window.URL.createObjectURL(new Blob([ new Uint8Array(receipt.photo.data).buffer ]))) ];
            }
        }

        this.setState({ [type]: newPhotos });

        this.setLoadingStatusOrderPhotos(type, false);

        if (newPhotos.length > 0) {
            callback();
        } else {
            Notification({
                message: 'Не удалось найти фотографии, попробуйте позже или обратитесь к администратору',
                type: NotificationTypes.error,
            });
        }
    };

    // Переключение лоадеров при подгрузке фотографий прикрепленных к заказу
    setLoadingStatusOrderPhotos = (type = '', status = false) => {
        type === 'providerPhotos' && this.setState({ isLoadingProviderPhotos: status });
        type === 'driverPhotos' && this.setState({ isLoadingDriverPhotos: status });
        type === 'returnPhotos' && this.setState({ isLoadingReturnPhotos: status });
        type === 'billOfLadingPhotos' && this.setState({ isLoadingBillOfLadingPhotos: status });
    };

    // Логика прикрепления фотографий накладных
    attachBillOfLadingPhotos = async (photos, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: "Вы действительно хотите прикрепить фотографии?",
                acceptButtonTitle: "Да, прикрепить",
                acceptButtonAction: this.attachBillOfLadingPhotos.bind(this, photos, true),
            });

            return
        }

        this.setState({ isShowBackdrop: true });

        const body = {
            orderId: this.state.orderId,
            billOfLadingPhotoInBase64: photos,
        };

        const response = await agent.post(`/orders/${ this.state.orderId }/attach-bill-of-lading`, body)
            .then(res => res.data)
            .catch(err => {
                return { error: err.response };
            });

        if (response.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                message: response.error?.data?.message || "Ошибка при добавлении фотографий",
                type: NotificationTypes.error,
            });

            return;
        }

        let newPhotos = [];

        if (Array.isArray(response.photos) && response.photos.length > 0 && this.state.billOfLadingPhotos.length > 0) {
            newPhotos = [ ...response.photos.map(photo => window.URL.createObjectURL(new Blob([ new Uint8Array(photo.data).buffer ]))) ];
        }

        await this.getOrder();

        this.setState({
            isShowBackdrop: false,
            billOfLadingPhotos: [ ...this.state.billOfLadingPhotos, ...newPhotos ],
        });

        Notification({
            message: "Фотографии успешно прикреплены",
            type: NotificationTypes.success,
        });

    };

    // Взятие зазаказа в работу
    takeWorkOrder = async (form, isConfirm) => {
        const order = this.state.order;

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите взять заказ?`,
                acceptButtonTitle: "Да, взять",
                acceptButtonAction: this.takeWorkOrder.bind(this, form, true)
            });

            return null
        }

        this.setState({ isShowBackdrop: true })

        const responsetakeWork = await agent.put(`/orders/${ order.id }/take-as-courier`, {
            ...form
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (responsetakeWork.error) {
            this.setState({ isShowBackdrop: false });

            const errorMessage = Boolean(responsetakeWork.error?.status === 403) ?
                "Нет прав" :
                responsetakeWork.error?.data?.message || "Возникла ошибка, попробуйте позднее";

            Notification({
                type: NotificationTypes.error,
                message: errorMessage
            });

            return null
        }

        await this.getOrder();

        this.setState({ isShowBackdrop: false })

        Notification({
            type: NotificationTypes.success,
            message: `Информация успешно обновлена`
        });
    };

    // Логика изменения водителя/депо/транеспорта
    changeDeliveryService = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите изменить водителя/базу/транспорт?`,
                acceptButtonTitle: "Да, изменить",
                acceptButtonAction: this.changeDeliveryService.bind(this, form, true)
            });

            return null
        }

        this.setState({ isShowBackdrop: true })

        const response = await agent.put(`/orders/${ this.state.order.id }/delivery-service-info`, form)
            .then(res => {
                if (this.state.order.deliveryStatus === 'courierInstalled') {
                    this.setOrderStatus('loaded', true);
                }

                return res.data;
            })
            .catch(err => {
                return { error: err.response }
            });

        if (response.error) {
            this.setState({ isShowBackdrop: false })
            Notification({
                message: response.error?.data?.message || "Ошибка при изменении водителя/базу/транспорт",
                type: NotificationTypes.error,
            })

            return null;
        }

        await this.getOrder();

        this.setState({ isShowBackdrop: false });

        Notification({
            message: "Водитель/база/транспорт успешно изменены",
            type: NotificationTypes.success,
        });
    };

    //Частичный возврат (return-fill)
    partialReturnFill = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите провести частичный возврат?`,
                acceptButtonTitle: "Да, провести",
                acceptButtonAction: this.partialReturnFill.bind(this, form, true)
            });

            return null;
        }

        this.setState({ isShowBackdrop: true })

        const response = await agent.put(`/returns/${ this.state.orderId }/return-fill`, form)
            .then(res => res.data)
            .catch(err => {
                return { error: err.response }
            });

        if (response.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                type: NotificationTypes.error,
                message: response.error.data.message || "Ошибка сервера",
            });

            return null
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({ isShowBackdrop: false });

        Notification({
            type: NotificationTypes.success,
            message: "Частичный возврат успешно проведен, ожидайте подтверждения от заказчика",
        });
    };
    //Частичный возврат (return-fill)
    fullReturnFill = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите провести полный возврат?`,
                acceptButtonTitle: "Да, провести",
                acceptButtonAction: this.fullReturnFill.bind(this, form, true)
            });

            return
        }

        this.setState({ isShowBackdrop: true });

        const response = await agent.put(`/returns/${ this.state.orderId }/return-fill`, form)
            .then(res => res.data)
            .catch(err => {
                return { error: err.response }
            });

        if (response.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                type: NotificationTypes.error,
                message: response.error.data.message || "Ошибка сервера",
            });

            return null
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({ isShowBackdrop: false });

        Notification({
            type: NotificationTypes.success,
            message: "Полный возврат успешно проведен, ожидайте подтверждения от заказчика",
        });
    };
    // Трансфер возврата до провайдера
    transferReturnToProvider = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить доставку возврата до провайдера?`,
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.transferReturnToProvider.bind(this, form, true)
            });

            return null;
        }

        this.setState({ isShowBackdrop: true });

        const response = await agent.put(`/returns/${ this.state.orderId }/transfer-return-to-provider`, form)
            .then(res => res.data)
            .catch(err => {
                return { error: err.response };
            });

        if (response.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                message: response.error?.data?.message || "Ошибка при доставке возврата до исполнителя",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();
        await this.getReturnItems();

        this.setState({ isShowBackdrop: false });

        Notification({
            message: "Доставка до исполнителя успешно подтверждено, дождитесь подтвеждения от исполнителя",
            type: NotificationTypes.success,
        });
    };

    // Подтверждение возврата
    confirmReturn = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить возврат?`,
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.confirmReturn.bind(this, form, true)
            });

            return null;
        }

        this.setState({ isShowBackdrop: true })

        const response = await agent.put(`returns/${ this.state.orderId }/return-confirmation`, {
            code: form.code,
        })
            .then(res => res.data)
            .catch(err => {
                return { error: err.response }
            });

        if (response.error) {
            this.setState({ isShowBackdrop: false })

            Notification({
                message: response.error?.data?.message || "Ошибка при подтверждении возврата",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();

        this.setState({ isShowBackdrop: false });

        Notification({
            message: "Возврат успешно подтвержден",
            type: NotificationTypes.success,
        });
    };

    // Отказаться от заказа
    cancelOrder = async (isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите отказаться от заказа?`,
                acceptButtonTitle: "Да, отказаться",
                acceptButtonAction: this.cancelOrder.bind(this, true)
            });

            return
        }

        this.setState({ isShowBackdrop: true });

        const response = await agent.put(`orders/${ this.state.orderId }/cancel`)
            .then(res => res.data)
            .catch(err => {
                return { error: err.response }
            });

        if (response.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                message: response.error?.data?.message || "Ошибка при отказе от заказа",
                type: NotificationTypes.error,
            });
        }

        this.setState({ isShowBackdrop: false });

        this.props.history.push(`/my-list`);

        Notification({
            message: "Вы успешно отказались от заказа",
            type: NotificationTypes.success,
        });
    };

    // Передача груза заказчику
    acceptOrder = async (code, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите передать груз заказчику?`,
                acceptButtonTitle: "Да, передать",
                acceptButtonAction: this.acceptOrder.bind(this, code, true),
            });

            return null;
        }

        this.setState({ isShowBackdrop: true });

        const response = await agent.put(`/orders/${ this.state.orderId }/confirm`, { code })
            .then(res => res.data)
            .catch(err => {
                return { error: err.response }
            });

        if (response.error) {
            this.setState({ isShowBackdrop: false });

            Notification({
                message: response.error?.data?.message || "Ошибка при передаче груза заказчику",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();
        this.setState({ isShowBackdrop: false });

        Notification({
            message: "Груз успешно передан заказчику. Заказ считается выполненным",
            type: NotificationTypes.success,
        });
    };

    // Логика работы по статусам
    setOrderStatus = async (status, isConfirm) => {

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите изменить статус заказа?`,
                acceptButtonTitle: "Да, изменить",
                acceptButtonAction: this.setOrderStatus.bind(this, status, true)
            });

            return
        }

        this.setState({ isShowBackdrop: true });

        if (status === "partialReturn" || status === 'fullReturn') {
            const response = await agent.put(`/returns/${ this.state.order.id }`, {
                orderId: this.state.order.id,
                returnType: status,
            }).then((res) => {
                return res.data
            }).catch((err) => {
                return { error: err.response }
            });
            if (response.error) {
                this.setState({ isShowBackdrop: false });
                Notification({
                    type: NotificationTypes.error,
                    message: response.error?.data?.message || "Ошибка сервера"
                })
                return
            }
            await this.getOrder();
            this.setState({ isShowBackdrop: false });
            Notification({
                type: NotificationTypes.success,
                message: "Статус заказа успешно изменен"
            });
        } else {
            const response = await agent.put(`/orders/${ this.state.order.id }/set-status`, {
                orderId: this.state.order.id,
                deliveryStatus: status,
            }).then((res) => {
                return res.data
            }).catch((err) => {
                return { error: err.response }
            });
            if (response.error) {
                this.setState({ isShowBackdrop: false });

                Notification({
                    type: NotificationTypes.error,
                    message: response.error?.data?.message || "Ошибка сервера"
                })

                return
            }

            await this.getOrder();

            this.setState({ isShowBackdrop: false });

            Notification({
                type: NotificationTypes.success,
                message: "Статус заказа успешно изменен",
            });
        }
    }

    // Логика подтверждения принятия груза
    transferTheGoodsConfirm = async (code, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить принятие груза?`,
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.transferTheGoodsConfirm.bind(this, code, true),
            });

            return null;
        }

        this.setState({ isShowBackdrop: true });

        const response = await agent.put(`/orders/${ this.state.orderId }/confirm`, {
            code,
        })
            .then(res => res.data)
            .catch(err => {
                return { error: err.response };
            })

        if (response.error) {
            this.setState({ isShowBackdrop: false });
            Notification({
                message: response.error?.data?.message || "Ошибка при подтверждении принятии заказа",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getOrder();

        this.setState({ isShowBackdrop: false });

        Notification({
            message: "Груз успешно принят",
            type: NotificationTypes.success,
        });
    };

    // Логика доставки заказа до заказчика
    deliveredToClient = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите подтвердить доставку заказа?`,
                acceptButtonTitle: "Да, подтвердить",
                acceptButtonAction: this.deliveredToClient.bind(this, form, true),
            });

            return null;
        }

        this.setState({ isShowBackdrop: true });

        const response = await agent.put(`/orders/${ this.state.orderId }/transfer`, {
            orderPhotosInBase64: form.photo,
        })
            .then(res => res.data)
            .catch(err => {
                return { error: err.response };
            })

        if (response.error) {
            this.setState({ isShowBackdrop: false });
            Notification({
                message: response.error?.data?.message || "Ошибка при подтверждении доставки заказа",
                type: NotificationTypes.error,
            })

            return null;
        }

        await this.getOrder();

        this.setState({ isShowBackdrop: false });

        Notification({
            message: "Заказ успешно доставлен",
            type: NotificationTypes.success,
        });
    };

    // Логика отправки ссылки на личный кабинет пользователя
    sendLinkToAccount = async () => {
        this.setState({ isLoadingSendLinkToAccount: true });

        const response = await agent.post('/messages', {
            userId: this.state.order.purchaserUserId,
        })
            .then(res => res.data)
            .catch(err => {
                return { error: err.response };
            });

        if (response.error) {
            this.setState({ isLoadingSendLinkToAccount: false });

            Notification({
                message: response.error?.data?.message || "Ошибка при отправке ссылки на личный кабинет",
                type: NotificationTypes.error,
            });

            return;
        }

        this.setState({ isLoadingSendLinkToAccount: false });

        Notification({
            message: "Ссылка на личный кабинет успешно отправлена",
            type: NotificationTypes.success,
        });
    };

    // Получение статусов по заказу
    _statusItems = () => {

        if (this.state.order.deliveryStatus === "canceled") {
            return [
                {
                    label: "Отменен",
                    active: true,
                    error: true,
                }
            ]
        }

        return [
            {
                label: "Принят в работу",
                active: true,
                error: false,
            },
            {
                label: "Загружен",
                active: [
                    "enRoute",
                    'transferringToClient',
                    "received",
                    "partialReturn",
                    "fullReturn",
                ].includes(this.state.order.deliveryStatus),
                error: false,
            },
            {
                label: "В пути",
                active: [
                    'enRoute',
                    'transferringToClient',
                    "received",
                    "partialReturn",
                    "fullReturn",
                ].includes(this.state.order.deliveryStatus),
                error: false,
            },
            {
                label: "Доставлен",
                active: [
                    'transferringToClient',
                    "received",
                    "partialReturn",
                    "fullReturn",
                ].includes(this.state.order.deliveryStatus),
                error: false,
            },
            {
                label: ([ 'fullReturn' ].includes(this.state.order.deliveryStatus) && this.state.order.statusOfReturnOrder === 'received' ? "Не выполнен" : "Выполнен"),
                active: [ "received" ].includes(this.state.order.deliveryStatus) || ([ 'partialReturn' ].includes(this.state.order.deliveryStatus) && this.state.order.statusOfReturnOrder === 'received'),
                error: ([ 'fullReturn' ].includes(this.state.order.deliveryStatus) && this.state.order.statusOfReturnOrder === 'received'),
            },
        ]
    }
    // Получение кнопок управление "Готовность"
    _buttonReady = () => {
        let buttons = [];

        const { order } = this.state;
        const deliveryStatus = order.deliveryStatus;

        if (deliveryStatus === 'transferringToDelivery' && userIsDriver(order)) {
            buttons.push({
                label: "Принять груз",
                onClick: this.setIsOpenTransferTheGoodsConfirm.bind(this, true)
            });
        }

        // Заказ принят
        if ([
            "enRoute",
        ].includes(deliveryStatus)) {
            buttons.push({ label: "Заказ доставлен", onClick: this.setIsOpenDeliveredToClient.bind(this, true) });
        }

        // Завершение заказа (передача заказа заказчику)
        if ([
            "transferringToClient",
        ].includes(deliveryStatus) || ([ "partialReturn" ].includes(deliveryStatus) && (order.discount !== 0 || order.adminDiscount !== 0) && order.statusOfReturnOrder === 'created')) {
            buttons.push({ label: "Передать груз", onClick: this.setIsOpenAcceptOrder.bind(this, true) });
        }

        // Частичный возврат (установка статуса, активировать кнопку "Скидка" у исполнителя)
        if ([
            "transferringToClient",
        ].includes(deliveryStatus)) {
            buttons.push({
                label: "Частичный возврат",
                onClick: this.setOrderStatus.bind(this, 'partialReturn', false)
            });
        }

        // Полный возврат
        if ([
            "transferringToClient",
        ].includes(deliveryStatus)) {
            buttons.push({ label: "Полный возврат", onClick: this.setOrderStatus.bind(this, 'fullReturn', false) });
        }

        //Частичный возврат (возврат товаров)
        if (deliveryStatus === 'partialReturn' && order.statusOfReturnOrder === 'created') {
            buttons.push({ label: "Возврат", onClick: this.setIsOpenPartialReturnFill.bind(this, true) });
        }

        // Полный возврат (возврат товаров)
        if (deliveryStatus === 'fullReturn' && order.statusOfReturnOrder === 'created') {
            buttons.push({ label: "Возврат", onClick: this.setIsOpenFullReturnFill.bind(this, true) });
        }

        if ([ 'partialReturn', 'fullReturn' ].includes(deliveryStatus) && [ 'courierFillConfirmed' ].includes(order.statusOfReturnOrder)) {
            buttons.push({
                label: "Возврат доставлен исполнителю",
                onClick: this.setIsOpenTransferReturnToProvider.bind(this, true),
            });
        }

        if ([ 'partialReturn', 'fullReturn' ].includes(deliveryStatus) && [ 'purchaserConfirmed', 'providerConfirmed' ].includes(order.statusOfReturnOrder)) {
            buttons.push({ label: "Подтвердить возврат", onClick: this.setIsOpenReturnConfirm.bind(this, true) });
        }

        return buttons;
    }
    _isVisibleActionsReady = () => {
        const { order } = this.state;

        return (([ 'partialReturn', 'fullReturn' ].includes(this.state.order.deliveryStatus) &&
                [ 'created', 'purchaserConfirmed', 'courierFillConfirmed', 'providerConfirmed' ].includes(this.state.order.statusOfReturnOrder))
            || [ "enRoute", 'transferringToClient', 'transferringToDelivery' ].includes(this.state.order.deliveryStatus)) && userIsDriver(order);
    };
    _isHideButtonCancel = () => {
        return [
            "enRoute",
            "received",
            "partialReturn",
            "fullReturn",
            "canceled"
        ].includes(this.state.order.deliveryStatus)
    };

    // Открытие формы заполнения частичного возврата
    setIsOpenPartialReturnFill = (isOpen) => {
        this.setState({ isOpenPartialReturnFill: isOpen });
    };
    // закрытие формы заполнения частичного возврата
    handleClosePartialReturnFill = () => {
        this.setIsOpenPartialReturnFill(false);
    }

    // Открытие формы заполнения полного возврата
    setIsOpenFullReturnFill = (isOpen) => {
        this.setState({ isOpenFullReturnFill: isOpen });
    };
    // Закрытие формы заполнения полного возврата
    handleCloseFullReturnFill = () => {
        this.setIsOpenFullReturnFill(false);
    };

    // Открытие формы трансфера возврата до провайдера
    setIsOpenTransferReturnToProvider = (isOpen) => {
        this.setState({ isOpenTransferReturnToProvider: isOpen })
    };
    // Закрытие формы трансфера возврата до провайдера
    handleCloseTransferReturnToProvider = () => {
        this.setIsOpenTransferReturnToProvider(false);
    };

    // Открытие формы подтверждения возврата
    setIsOpenReturnConfirm = (isOpen) => {
        this.setState({
            isOpenReturnConfirm: isOpen,
        });
    };
    // Закрытие формы подтверждения возврата
    handleCloseReturnConfirm = () => {
        this.setIsOpenReturnConfirm(false);
    };

    // Открытие формы подтверждения принятия груза
    setIsOpenTransferTheGoodsConfirm = (isOpen) => {
        this.setState({
            isOpenTransferTheGoodsConfirm: isOpen,
        })
    };
    // Закрытие формы подтверждения принятия заказа
    handleCloseTransferTheGoodsConfirm = () => {
        this.setIsOpenTransferTheGoodsConfirm(false);
    };

    // Открытие формы доставки до заказчика
    setIsOpenDeliveredToClient = (isOpen) => {
        this.setState({
            isOpenDeliveredToClient: isOpen,
        })
    };
    // Закрытие формы доставки до заказчика
    handleCloseDeliveredToClient = () => {
        this.setIsOpenDeliveredToClient(false);
    };

    // Открытие формы завершение заказа (передачи заказа заказчику)
    setIsOpenAcceptOrder = (isOpen) => {
        this.setState({
            isOpenAcceptOrder: isOpen,
        });
    };

    // Закрытие формы завершения заказа (передачи заказа заказчику)
    handleCloseAcceptOrder = () => {
        this.setIsOpenAcceptOrder(false);
    };

    //Открытие формы добавления фотографии накладных
    setIsOpenAddBillOfLadingPhotos = (isOpen) => {
        this.setState({ isOpenAddBillOfLadingPhotos: isOpen });
    };
    // Закрытие формы добавления фотографии накладных
    handleCloseAddBillOfLadingPhotos = () => {
        this.setIsOpenAddBillOfLadingPhotos(false);
    };

    render() {
        const {
            order,
            returnItems,
            shortageItems,
            billOfLadings,
            orderId,
            settings,

            providerPhotos,
            driverPhotos,
            returnPhotos,
            billOfLadingPhotos,

            adminsPhones,

            isOpenTransferTheGoodsConfirm,
            isOpenDeliveredToClient,
            isOpenAcceptOrder,

            isOpenPartialReturnFill,
            isOpenFullReturnFill,
            isOpenTransferReturnToProvider,
            isOpenReturnConfirm,
            isOpenAddBillOfLadingPhotos,

            isLoading,
            isLoadingProviderPhotos,
            isLoadingDriverPhotos,
            isLoadingReturnPhotos,
            isLoadingBillOfLadingPhotos,
            isLoadingBillOfLading,
            isLoadingSendLinkToAccount,

            isShowBackdrop
        } = this.state;
        const {
            stores,
            classes
        } = this.props;

        if (Object.keys(order).length <= 0) {
            return (
                <Typography variant="h3">Загружаем заказ</Typography>
            )
        }

        return (
            <>

                <Box mb={ 2 }>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container alignItems="center" spacing={ 2 }>
                                <Grid item>
                                    <Typography variant="h1">
                                        Заказ №{ orderId }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <LifeCycleComponent
                                        items={ this._statusItems() }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        { Boolean(!this._isHideButtonCancel()) && (
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={ { height: 40, borderRadius: 0 } }
                                    onClick={ () => this.cancelOrder() }
                                >
                                    Отказаться от заказа
                                </Button>
                            </Grid>
                        ) }
                    </Grid>
                </Box>

                <Box className={ classes.content }>

                    { !isLoading && (
                        <InformationAddresses
                            order={ order }
                            settings={ settings }
                            billOfLadings={ billOfLadings }
                            isLoadingBillOfLading={ isLoadingBillOfLading }
                            isLoadingSendLinkToAccount={ isLoadingSendLinkToAccount }
                            onGetBillOfLading={ this.getBillOfLading }
                            setIsOpenAddBillOfLadingPhotos={ this.setIsOpenAddBillOfLadingPhotos }
                            onSendLinkToAccount={ this.sendLinkToAccount }
                        />
                    ) }

                    <Box mt={ 4 }/>
                    <Divider sx={ { margin: "0 -24px" } }/>
                    <Box mt={ 4 }/>

                    { adminsPhones.length > 0 && (
                        <AdminsPhonesComponent phones={ adminsPhones }/>
                    ) }

                    <Box mt={ 4 }/>
                    <Divider sx={ { margin: "0 -24px" } }/>
                    <Box mt={ 4 }/>

                    <InformationProducts
                        products={ order?.orderItems || [] }
                        returnItems={ returnItems }
                        shortageItems={ shortageItems }
                    />

                    <Box mt={ 4 }/>
                    <Divider sx={ { margin: "0 -24px" } }/>
                    <Box mt={ 4 }/>

                    { (order.attachedPhotosFlags &&
                        (
                            order.attachedPhotosFlags.billOfLadingPhotos ||
                            order.attachedPhotosFlags.fromDriver ||
                            order.attachedPhotosFlags.fromProvider
                        )
                    ) && (
                        <OrderPhotosComponent
                            order={ order }
                            orderDeliveryStatus={ this.state.order?.deliveryStatus || "" }
                            attachedPhotoFlags={ order.attachedPhotosFlags }

                            providerPhotos={ providerPhotos }
                            driverPhotos={ driverPhotos }
                            returnPhotos={ returnPhotos }
                            billOfLadingPhotos={ billOfLadingPhotos }

                            isLoadingProviderPhotos={ isLoadingProviderPhotos }
                            isLoadingDriverPhotos={ isLoadingDriverPhotos }
                            isLoadingReturnPhotos={ isLoadingReturnPhotos }
                            isLoadingBillOfLadingPhotos={ isLoadingBillOfLadingPhotos }

                            getPhotos={ this.getOrderPhotosUsingType }
                            setIsOpenAddBillOfLadingPhotos={ this.setIsOpenAddBillOfLadingPhotos }
                        />
                    ) }

                    <Box mt={ 4 }/>
                    <Divider sx={ { margin: "0 -24px" } }/>
                    <Box mt={ 4 }/>

                    <ActionForm
                        order={ order }
                        onTakeWork={ this.changeDeliveryService }
                    />

                    <Box mt={ 4 }/>
                    <Divider sx={ { margin: "0 -24px" } }/>
                    <Box mt={ 4 }/>


                    { Boolean(this._isVisibleActionsReady()) && (
                        <ReadyOrderInformationComponent
                            actions={ this._buttonReady() }
                        />
                    ) }

                </Box>

                <DialogTransferTheGoodsConfirmComponent
                    isOpen={ isOpenTransferTheGoodsConfirm }
                    onClose={ this.handleCloseTransferTheGoodsConfirm }
                    onConfirm={ this.transferTheGoodsConfirm }
                />

                <DialogFormDeliveredToClient
                    order={ order }
                    isOpen={ isOpenDeliveredToClient }
                    onClose={ this.handleCloseDeliveredToClient }
                    onDelivered={ this.deliveredToClient }
                />

                { [ 'fullReturn', 'partialReturn' ].includes(order.deliveryStatus) && (
                    <>
                        <DialogPartialReturnFillComponent
                            order={ order }
                            isOpen={ isOpenPartialReturnFill }
                            onClose={ this.handleClosePartialReturnFill }
                            onReturnFill={ this.partialReturnFill }
                        />

                        <DialogFullReturnFillComponent
                            order={ order }
                            isOpen={ isOpenFullReturnFill }
                            onClose={ this.handleCloseFullReturnFill }
                            onReturnFill={ this.fullReturnFill }
                        />

                        <DialogReturnConfirmComponent
                            isOpen={ isOpenReturnConfirm }
                            onClose={ this.handleCloseReturnConfirm }
                            onConfirm={ this.confirmReturn }
                        />

                        { [ 'courierFillConfirmed' ].includes(order.statusOfReturnOrder) && (
                            <DialogTransferReturnToProviderComponent
                                order={ order }
                                returnItems={ returnItems }
                                isOpen={ isOpenTransferReturnToProvider }
                                onClose={ this.handleCloseTransferReturnToProvider }
                                onTransfer={ this.transferReturnToProvider }
                            />
                        ) }
                    </>
                ) }

                <DialogAddBillOfLadingPhotosComponent
                    isOpen={ isOpenAddBillOfLadingPhotos }

                    onAttach={ this.attachBillOfLadingPhotos }
                    onClose={ this.handleCloseAddBillOfLadingPhotos }
                />

                <DialogConfirmation
                    ref={ this.refDialogConfirmation }
                />

                <DialogAcceptOrderComponent
                    isOpen={ isOpenAcceptOrder }
                    onClose={ this.handleCloseAcceptOrder }
                    onAccept={ this.acceptOrder }
                />

                <Backdrop open={ isShowBackdrop }>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </>
        );
    }
}

const styles = {
    content: {
        padding: 24,
        borderRadius: 10,
        backgroundColor: "#FBFBFB"
    }
};

export default withStyles(styles)(MyOrder)
