import React from "react";
import {
    Box,
    Grid,
    Button,
    Collapse,
    Typography
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Clear as ClearIcon,
    Search as SearchIcon,
    PlusOne as PlusOneIcon,
    FilterAlt as FilterAltIcon,
    HighlightOff as HighlightOffIcon
} from "@mui/icons-material";
import {

} from "../../../../../../components";

const Filter = (props) => {
    const {
        filter,

        onCreateUser,
        onReset,
        onSearch,
        onChange
    } = props;

    const [ isOpen, setOpen ] = React.useState(false);

    const classes = useStyles();

    const handleChangeCommon = ({ target }) => {
        const { name, value } = target;

        let newFilter = {...filter};
        newFilter[name] = value;

        onChange(newFilter);
    }

    const _activeFilters = () => {
        let items = [];

        return items
    }
    const _deleteCommon = (name) => {
        let newFilter = {...filter};
        newFilter[name] = "";

        onChange(newFilter, true);
    }

    return (
        <Box mb={1}>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>

                </Grid>
                <Grid item>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                onClick={onReset}
                                endIcon={<ClearIcon/>}
                            >
                                Сбросить
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                sx={{textTransform: "initial", fontSize: 12, height: "auto", borderRadius: 1}}
                                endIcon={<SearchIcon/>}
                                onClick={onSearch}
                            >
                                Поиск
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Box my={1}/>

            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box px={2} py={2} bgcolor="white" borderRadius={4}>
                    <Grid container spacing={4}>

                    </Grid>
                </Box>
            </Collapse>

            {Boolean(_activeFilters().length > 0) && (
                <>

                    <Box my={1}/>

                    <Grid container spacing={2}>
                        {_activeFilters().map((item, index) => (
                            <Grid item>
                                <Box className={classes.active} onClick={item.onClick}>
                                    <Typography className={classes.activeLabel}>{ item.label }:</Typography>
                                    <Typography className={classes.activeValue}>{ item.value }</Typography>
                                    <HighlightOffIcon sx={{color: "white", fontSize: 16, marginLeft: 1}}/>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                </>
            )}

        </Box>
    )
}

const useStyles = makeStyles(() => ({

    active: {
        display: "flex",
        alignItems: "center",
        padding: "2px 12px",
        borderRadius: 6,
        backgroundColor: "#FB7800",
        cursor: "pointer",

        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600"
    },
    activeLabel: {
        opacity: 0.6,
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white"
    },
    activeValue: {
        fontSize: 15,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
        marginLeft: 6
    },

}));

export default Filter
