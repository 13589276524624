import React from "react";
import {Redirect, Switch} from "react-router-dom";

import {RouteWithLayout} from "../components";
import {Default as DefaultLayout} from "../layouts";
import {
    Accreditation as AccreditationPage,
    ListOrder as ListOrderPage,
    ListOrders as ListOrdersPage,
    MyOrder as MyOrderPage,
    MyOrders as MyOrdersPage,
    MyStore as MyStorePage,
    MyStores as MyStoresPage,
    Profile as ProfilePage,
    Rules as RulesPage,
    DriversList as DriversListPage,
    TransportList as TransportListPage,
    CarBasesList as CarBasesListPage,
    Wallet as WalletPage
} from "../pages";

const pages = [

    {
        path: '/list',
        component: ListOrdersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/list/:id',
        component: ListOrderPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/my-list',
        component: MyOrdersPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/my-list/order/:id',
        component: MyOrderPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/profile',
        component: ProfilePage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/rules',
        component: RulesPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/accreditation',
        component: AccreditationPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/my-stores',
        component: MyStoresPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/my-stores/:id',
        component: MyStorePage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },

    {
        path: '/car-bases',
        component: CarBasesListPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/drivers',
        component: DriversListPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/transport',
        component: TransportListPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
    {
        path: '/wallet',
        component: WalletPage,
        layout: DefaultLayout,
        rules: [],
        exact: true
    },
];

const MainRoutes = () => {
    return (
        <Switch>
            {
                pages.map((page, idx) => (
                    <RouteWithLayout
                        key={'page-' + idx}
                        {...page}
                    />
                ))
            }

            <Redirect to="/list"/>
        </Switch>
    );
};

export default MainRoutes;
