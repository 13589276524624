import React from "react";
import {
    Box,
    Typography,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    numberFormat
} from "../../../../../../common/Formater";
import {
    convertorToPiece
} from "../../../../../../helper/convertor";

const InformationProducts = (props) => {
    const {
        products
    } = props;
    const classes = useStyles();

    const renderProductQuantity = (product) => {
        const piece = convertorToPiece(product.quantity, product?.product?.pieceToCbmConversionFactor || 0);
        return (
            <>
                <Typography>{piece} шт.</Typography>
                <Typography>{numberFormat(product.quantity, 4)} м³</Typography>
            </>
        );
    };

    return (
        <Box>

            <Box mb={1}>
                <Typography variant="h3">
                    Состав заказа
                </Typography>
            </Box>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell width={64}>№</TableCell>
                        <TableCell width="32%">Наименование товара</TableCell>
                        <TableCell>Количество</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((product, index) => (
                        <TableRow key={`row-${ index }`}>
                            <TableCell>{ index + 1 }</TableCell>
                            <TableCell>
                                { product.productName }
                            </TableCell>
                            <TableCell>
                                {renderProductQuantity(product)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </Box>
    )
}

const useStyles = makeStyles(() => ({
    table: {
        "& tr th": {
            fontSize: 12,
            opacity: 0.8,
            color: "black",
            fontWeight: "400",
            backgroundColor: "transparent",
            border: "none",
            padding: "8px 20px"
        },
        "& tr td": {
            fontSize: 14,
            color: "black",
            borderBottom: "8px solid rgb(248, 248, 248)",
            fontWeight: "400",
            backgroundColor: "white",

            "&:first-child": {
                borderRadius: "12px 0 0 12px!important"
            },
            "&:last-child": {
                borderRadius: "0 12px 12px 0!important"
            },
        },
    }
}));

export default InformationProducts
