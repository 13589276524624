import React from "react";
import {
    Box,
    Grid,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import {
    Notification,
    NotificationTypes
} from "../../common/Notification";
import AutocompleteDriver from "../autocomplete/AutocompleteDriver"

const FormDriver = (props) => {
    const {
        isCreated,
        onSubmit
    } = props;
    const [ driverId, setDriverId ] = React.useState(undefined);

    const handleChangeDriver = ({ target }) => {
        const { value } = target;

        setDriverId(value)
    }
    const handleSubmit = () => {
        if (typeof driverId === "undefined") {
            Notification({
                type: NotificationTypes.error,
                message: "Выберите водителя"
            })

            return null
        }

        onSubmit(driverId, handleClearForm)
    }

    const handleClearForm = () => {
        setDriverId(undefined)
    }

    return (
        <Box>

            <Typography variant="h5">Форма создание пары</Typography>

            <Box mt={2}/>

            <AutocompleteDriver
                value={driverId}
                onChange={handleChangeDriver}
            />

            <Box mt={2}/>

            <Button
                variant="contained"
                fullWidth
                disabled={isCreated}
                onClick={handleSubmit}
            >
                {isCreated ? "Создаем связь..." : "Добавить связь"}
            </Button>

        </Box>
    )
}

export default FormDriver
