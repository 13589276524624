import React, { Component } from "react";
import {Box, Typography} from "@mui/material";
import {
    Filter as FilterComponent,
    Table as TableComponent,
    OrdersTypeButtons as OrdersTypeButtonsComponent
} from "./components";
import queryString from "query-string";
import agent from "../../../../agent/agent";

const initialFilter = {
    "sortInfo": "-id",
    //---------------------
    "type": "current",
    //---------------------
};

const visibleColumns = [
    {
        id: "id",
        label: "№",
        sortable: true,
        width: 'auto',
    },
    {
        id: "deliveryDate",
        label: "Срок доставки",
        sortable: true,
        width: 'auto',
    },
    {
        id: "orderItems",
        label: "Груз (наименование товара)",
        sortable: false,
        width: 'auto',
    },
    {
        id: "storeAddress",
        label: "Адрес отправки",
        sortable: false,
        width: 'auto',
    },
    {
        id: "deliveryAddress",
        label: "Адрес доставки",
        sortable: false,
        width: 'auto',
    },
    {
        id: "finalDeliveryPrice",
        label: "Стоимость доставки",
        sortable: false,
        width: 'auto',
    },
];

class MyOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            settings: {},

            isLoading: true
        };
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getSettings();
        await this.getOrders();
    }
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }


    // Логика получения списка моих заказов
    getOrders = async () => {
        this.setState({isLoading: true});

        const filter = this.getFilters();
        const {
            orders,
            totalCount
        } = await agent.get(`/orders${filter}&limit=20&listing=false`).then((res) => {
            return {
                orders: res.data.orders || [],
                totalCount: res.data.count
            }
        }).catch((err) => {
            return {
                orders: [],
                totalCount: 0
            }
        });


        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(totalCount / 20) || 1;

        this.setState({
            isLoading: false,
            pagination,
            orders
        });
    }

    getSettings = async () => {
        const settings = await agent.get(`/settings`)
            .then((res) => res.data.settings)
            .catch((err) => {
            });

        this.setState({settings});
    }

    // Логика работы с фильтром
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `/my-list?${string.join("&")}`);

        return `?${string.join("&")}`
    }
    changeFilter = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getOrders();

    }
    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getOrders();
    }
    resetFilter = async () => {
        await this.setState({filter: {...initialFilter}});
        await this.getUsers();
    }

    // Типы заказов
    ordersTypesButtons = () => {
        return [
            {
                id: 1,
                title: 'Текущие',
                value: 'current',
            },
            {
                id: 2,
                title: 'Архивные',
                value: 'archive',
            },
        ];
    };

    // Логика работы навигации
    _routeOrder = (order) => {
        this.props.history.push(`/my-list/order/${ order.id }`);
    }

    render() {
        const {
            orders,

            filter,
            pagination,

            settings,

            isLoading,
            isShowBackdrop
        } = this.state;

        return (
            <>

                <Box mt={ 2 } mb={ 2 }>
                    <Typography variant="h1" textAlign="left">Мои заказы</Typography>
                    <Box mt={ 2 }>
                        <OrdersTypeButtonsComponent
                            ordersTypesButtons={ this.ordersTypesButtons() }
                            filter={ filter }
                            ordersType={ filter?.type || '' }
                            onChangeType={ this.changeFilter }
                        />
                    </Box>
                </Box>

                <FilterComponent
                    filter={ filter }
                />

                <TableComponent
                    data={ orders }
                    filter={ filter }
                    visibleColumns={ visibleColumns }
                    pagination={ pagination }
                    settings={ settings }

                    onChangePagination={ this.changePagination }
                    routeOrder={ this._routeOrder }
                    onChangeFilter={ this.changeFilter }
                />

            </>
        );
    }
}

export default MyOrders
