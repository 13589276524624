import React, {useRef} from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    Grid,
    IconButton,

    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import {
    AttachFileOutlined as AttachFileIcon,
    Delete as DeleteIcon,
    Upload as UploadIcon
} from '@mui/icons-material';
import {
    LoadingButton
} from "@mui/lab";
import {
    convertorToPiece,
    getBase64
} from "../../../../../../helper/convertor";
import {
    Formik
} from 'formik';
import {
    Notification,
    NotificationTypes
} from "../../../../../../common/Notification";
import {
    numberFormat
} from "../../../../../../common/Formater";
import {makeStyles} from "@mui/styles";

const DialogFormDeliveredToClient = (props) => {
    const {
        order,
        isOpen,
        onClose,
        onDelivered,
    } = props;

    const classes = useStyles();
    const [isLoadingImage, setIsLoadingImage] = React.useState(false);
    const refFormik = useRef(null);
    const initialValues = {
        photo: [],
        deliveredItems: order.orderItems.map(item => {
            let pieceQuantity, cbmQuantity;

            pieceQuantity = convertorToPiece(item.quantity, item?.product?.pieceToCbmConversionFactor || 0);
            cbmQuantity = item.quantity;

            return {
                ...item,
                deliveredValue: pieceQuantity,
                deliveredPiece: pieceQuantity,
                deliveredCbm: cbmQuantity,
            };
        }),
    }

    const onSubmit = async (form) => {
        if (form.photo.length > 0) {
            const photosBase64 = [];
            for await (let photo of form.photo) {
                let photoBase64 = await getBase64(photo.file);
                photosBase64.push(photoBase64);
            }
            const newForm = form;
            newForm.photo = photosBase64;
            await onDelivered(newForm);
            handleCloseModal();
        } else {
            Notification({
                message: "Прикрепите фотографии",
                type: NotificationTypes.error,
            });
        }
    };

    const handleCloseModal = () => {
        refFormik.current.resetForm();
        onClose();
    };

    const handleChangeImage = (event) => {
        setIsLoadingImage(true);
        const file = event?.target.files?.[0] || null;

        let newForm = refFormik.current?.values || {};
        let image_id = {
            file: '',
            source: '',
        };
        image_id.file = file;
        image_id.source = URL.createObjectURL(file);
        newForm.photo = [...newForm.photo, image_id];
        refFormik.current.setValues(newForm);
        setIsLoadingImage(false);
    };

    const handleDeleteImage = (index) => {
        const newForm = refFormik.current.values;
        newForm.photo.splice(index, 1);

        refFormik.current.setValues(newForm);
    };

    const renderQuantity = (orderItem) => {
        const piece = convertorToPiece(orderItem.quantity, orderItem?.product?.pieceToCbmConversionFactor || 0)
        return (
            <>
                <Typography mt="16px">
                    {piece} шт
                </Typography>
                <Typography>
                    {numberFormat(orderItem.quantity, 4, '.')} м³
                </Typography>
            </>
        );
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isOpen}
            onClose={handleCloseModal}
        >
            <DialogTitle>
                Заказ доставлен заказчику
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValues}
                    innerRef={refFormik}

                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            handleSubmit
                        } = props;
                        return (
                            <>
                                <Box>
                                    <Box>
                                        {values.photo.length > 0 &&
                                            <>
                                                {values.photo.map((photo, index) => (
                                                    <>
                                                        <Box
                                                            key={`delivered-to-client-image-${photo?.file?.name || 'fileName'}-${index}`}
                                                        >
                                                            <ReturnImage
                                                                imageId={photo}
                                                                indexPhoto={index}
                                                                viewImage
                                                            />
                                                            <ReturnImage
                                                                imageId={photo}
                                                                indexPhoto={index}
                                                                viewFile
                                                                handleDeleteImage={handleDeleteImage}
                                                            />
                                                        </Box>
                                                    </>
                                                ))}
                                            </>
                                        }

                                        <LoadingButton
                                            loading={isLoadingImage}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                        >
                                            <label style={{
                                                width: "100%",
                                                cursor: "pointer",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}>
                                                <UploadIcon sx={{marginRight: "5px"}}/>
                                                Выберите файл
                                                <input
                                                    width="100%"
                                                    type="file"
                                                    name="image_id"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(event) => handleChangeImage(event)}
                                                />
                                            </label>
                                        </LoadingButton>
                                    </Box>
                                    <Box mt={2}>
                                        <Typography mb={1}>
                                            Товары которые были доставлены
                                        </Typography>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="40%">Наименование</TableCell>
                                                    <TableCell>Кол-во</TableCell>
                                                    <TableCell>Доставлено</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {values.deliveredItems.map((val) => (
                                                    <TableRow key={`item-${val.id}`}>
                                                        <TableCell>{val.productName}</TableCell>
                                                        <TableCell>{renderQuantity(val)}</TableCell>
                                                        <TableCell>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Grid container alignItems="flex-end"
                                                                          spacing={1}>
                                                                        <Grid item>
                                                                            <TextField
                                                                                sx={{
                                                                                    width: "50px",
                                                                                    height: "30px",
                                                                                    "& div": {
                                                                                        height: "30px",
                                                                                    },
                                                                                    "& input": {
                                                                                        padding: "0px 6px",
                                                                                        textAlign: "center",
                                                                                    }
                                                                                }}
                                                                                value={val.deliveredValue}
                                                                                name="deliveredValue"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography
                                                                                variant="h6">шт.</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid container alignItems="flex-end"
                                                                          spacing={1}>
                                                                        <Grid item>
                                                                            <TextField
                                                                                sx={{
                                                                                    width: "50px",
                                                                                    height: "30px",
                                                                                    "& div": {
                                                                                        height: "30px",
                                                                                    },
                                                                                    "& input": {
                                                                                        padding: "0px 6px",
                                                                                        textAlign: "center",
                                                                                    }
                                                                                }}
                                                                                disabled
                                                                                value={val.deliveredCbm}
                                                                                name="deliveredCbm"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography
                                                                                variant="h6">м³</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Box>

                                <DialogActions>
                                    <>
                                        <Button variant="outlined" onClick={handleCloseModal}>Отменить</Button>
                                        <Button variant="contained"
                                                onClick={handleSubmit}>Отправить</Button>
                                    </>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const ReturnImage = React.memo((props) => {
    const {
        imageId,
        indexPhoto,
        viewImage,
        viewFile,

        handleDeleteImage
    } = props;

    return (<>
        {Boolean(viewImage)
            && (
                <Box
                    width="100%"
                    height={500}
                    sx={{
                        backgroundColor: "rgba(132,185,44, .2)",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 10,
                        boxSizing: "border-box",
                    }}
                    mb={2}
                >
                    <img src={imageId.source} style={{
                        display: "block", width: "100%", height: "100%", objectFit: "contain", borderRadius: 5,
                    }} alt=""/>
                </Box>
            )
        }
        {Boolean(viewFile)
            && (
                <Box display="flex" mb={2}>
                    <Box
                        sx={{
                            flex: 8,
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                            background: "#84b92c",
                            borderRadius: "4px",
                            color: "#fff",
                            fontSize: "14px",
                            justifyContent: "center",
                            marginRight: "5px"
                        }}
                    >
                        <AttachFileIcon
                            sx={{
                                marginRight: "5px",
                            }}
                        />
                        {imageId?.file?.name}
                    </Box>
                    <IconButton
                        color="error"

                        onClick={() => handleDeleteImage(indexPhoto)}
                    >
                        <Tooltip title="Удалить файл">
                            <DeleteIcon/>
                        </Tooltip>
                    </IconButton>
                </Box>
            )
        }
    </>);
});

const useStyles = makeStyles({
    uploadButton: {
        "&.MuiButton-root": {
            padding: 0,

            "& > label": {
                padding: 0,
                height: "100%",
            },
        },
    },
});

export default DialogFormDeliveredToClient;