import React from "react";
import {
    Box,
    Typography,
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import clsx from "clsx";

const LifeCycle = (props) => {
    const {
        items
    } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>

            {items.map((status, index) => (
                <Box
                    key={`life-cycle-card-${index}`}
                    className={clsx({
                        [classes.statusCard]: true,
                        [classes.statusCardActive]: status.active,
                        [classes.statusCardError]: status.error,
                    })}
                >
                    <Typography className={classes.statusCardLabel}>
                        {status.label}
                    </Typography>
                </Box>
            ))}

        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",

        "& > *": {
            marginLeft: 4,

            "&:first-child": {
                marginLeft: 0,
                padding: "0 24px",

                "&:after": {
                    content: "none"
                }
            }
        }
    },

    statusCard: {
        height: 40,
        padding: "0 12px 0 24px",
        backgroundColor: "#757575",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",

        "&:after": {
            content: "''",
            position: "absolute",
            left: 0,
            border: "20px solid transparent",
            borderLeft: "16px solid white"
        },
        "&:before": {
            content: "''",
            position: "absolute",
            left: "100%",
            border: "20px solid transparent",
            borderLeft: "16px solid #757575",
            zIndex: 1
        },
    },
    statusCardActive: {
        backgroundColor: "#07bc0c",

        "&:before": {
            borderLeftColor: "#07bc0c",
        }
    },
    statusCardError: {
        backgroundColor: "#A90505",

        "&:before":  {
            borderLeftColor: "#A90505",
        }
    },
    statusCardLabel: {
        fontSize: 16,
        lineHeight: "19px",
        color: "white"
    },
}));

export default LifeCycle
