import React from "react";
import {Map as YMap, Placemark} from "@pbe/react-yandex-maps";

class Map extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            marker: {}
        };
    }


    // Обработчик клика по карте
    clickMap = (event) => {
        const onChangeCoords = this.props.onChangeCoords;

        if (!onChangeCoords) {
            return null
        }

        onChangeCoords(event.get("coords"));
    }

    render() {
        const {
            classes,
            coords,
            center
        } = this.props;

        return (
            <YMap
                defaultState={{
                    center: center || [56.838011, 60.597465],
                    zoom: 10,
                    controls: ['zoomControl'],
                }}
                style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 8
                }}
                onClick={this.clickMap}
            >
                {(coords[0] && coords[1]) && (
                    <Placemark geometry={coords}/>
                )}
            </YMap>
        );
    }
}


Map.defaultProps = {
    coords: [],
};

export default Map
