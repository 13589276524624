import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Pagination,

    Tooltip,
    IconButton,
} from "@mui/material";
import {
    DeleteRounded as DeleteIcon,
    Edit as EditIcon,
} from "@mui/icons-material";
import {
    makeStyles
} from "@mui/styles";
import {
    Skeleton
} from "@mui/lab";

const TableCustom = (props) => {
    const {
        data,
        pagination,

        isLoading,

        onChangePagination,
        onEditTransport,
        onDeleteTransport
    } = props;

    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell>Марка</TableCell>
                        <TableCell>Модель</TableCell>
                        <TableCell>Гос.номер</TableCell>
                        <TableCell align="center" width="120px"/>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoading) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>
                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (
                                        <TableRow key={`table-row-${index}`}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>{row.make}</TableCell>
                                            <TableCell>{row.model}</TableCell>
                                            <TableCell>{row.licensePlate}</TableCell>
                                            <TableCell align="right">
                                                <Box className={classes.rowContent}>
                                                    <Tooltip title="Редактировать" arrow>
                                                        <IconButton onClick={() => onEditTransport(row)}>
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip
                                                        title="Удалить"
                                                        classes={{
                                                            popper: classes.deleteTooltip,
                                                        }}
                                                    >
                                                        <IconButton
                                                            color="error"

                                                            onClick={onDeleteTransport.bind(this, row.id, false)}
                                                        >
                                                            <DeleteIcon color="error"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
            </Grid>

        </>
    );
}

const useStyles = makeStyles(() => ({
    rowContent: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },

    deleteTooltip: {
        "&.MuiTooltip-popper": {
            '& .MuiTooltip-tooltip': {
                background: "#e53935",
            }
        }
    },
}));

export default TableCustom
