import React from "react";
import {
    Box,
    Grid,
    IconButton,
    Pagination,

    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

    Tooltip
} from "@mui/material";
import {
    Skeleton
} from "@mui/lab";
import {
    Edit as EditIcon,
    DeleteRounded as DeleteIcon,
} from "@mui/icons-material";
import {
    makeStyles
} from "@mui/styles";

const TableCustom = (props) => {
    const {
        data,
        pagination,

        isLoading,

        onChangePagination,
        onOpenFormEdit,
        onDeleteCarBase,
    } = props;

    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell>Наименование</TableCell>
                        <TableCell>Описание</TableCell>
                        <TableCell>Полный адрес</TableCell>
                        <TableCell width="120px" align="center"/>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {Boolean(isLoading)
                        ? (
                            <>

                                {[0, 1, 2, 3, 4, 5].map((item) => (
                                        <TableRow key={`row-item-load-${item}`}>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                            <TableCell><Skeleton/></TableCell>
                                        </TableRow>
                                    )
                                )}
                            </>
                        )
                        : (
                            <>
                                {data.map((row, index) => (
                                        <TableRow key={`table-row-${index}`}>
                                            <TableCell>{row.id}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.description}</TableCell>
                                            <TableCell>{row.address}</TableCell>
                                            <TableCell>
                                                <Box
                                                    width="max-content"
                                                    className={classes.rowContent}
                                                >
                                                    <Tooltip title='Редактировать'>
                                                        <IconButton
                                                            color="primary"
                                                            onClick={onOpenFormEdit.bind(this, true, false, row)}
                                                        >
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip
                                                        title="Удалить"
                                                        classes={{
                                                            popper: classes.deleteTooltip,
                                                        }}
                                                    >
                                                        <IconButton
                                                            color="error"

                                                            onClick={onDeleteCarBase.bind(this, row.id, false)}
                                                        >
                                                            <DeleteIcon color="error"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </>
                        )
                    }
                </TableBody>
            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
            </Grid>
        </>
    );
}

const useStyles = makeStyles(() => ({
    rowContent: {
        display: "flex",
        alignItems: "center",
        gap: 8,
    },

    deleteTooltip: {
        "&.MuiTooltip-popper": {
            '& .MuiTooltip-tooltip': {
                background: "#e53935",
            }
        }
    },
}));

export default TableCustom
