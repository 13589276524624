import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    TextField,
    Typography
} from "@mui/material";
import {
    Formik
} from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import regexp from "../../../../../../constants/regexp";

const DialogCreateDriver = (props) => {
    const {
        isOpen,
        onClose,
        onCreate
    } = props;

    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        phone: "",
    });

    React.useEffect(() => {
        if (isOpen) {
            setInitialValues({
                firstName: "",
                lastName: "",
                username: "",
                email: "",
                phone: "",
            })
        }
    }, [isOpen]);

    const onSubmit = (form) => {
        onCreate(form);
        handleCloseModal();
    };

    const handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues(newForm);
    }

    const handleCloseModal = () => {
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">
                    Создание водителя
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1}>
                                    <Box mb={2}>
                                        <TextField
                                            value={values.firstName}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                            name="firstName"
                                            label="Имя пользователя"
                                            placeholder="Введите имя пользователя"
                                            variant="outlined"
                                            fullWidth

                                            onChange={handleChange}
                                        />
                                    </Box>

                                    <Box mb={2}>
                                        <TextField
                                            value={values.lastName}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                            name="lastName"
                                            label="Фамилия пользователя"
                                            placeholder="Введите фамилию пользователя"
                                            variant="outlined"
                                            fullWidth

                                            onChange={handleChange}
                                        />
                                    </Box>

                                    <Box mb={2}>
                                        <TextField
                                            value={values.username}
                                            error={Boolean(touched.username && errors.username)}
                                            helperText={touched.username && errors.username}
                                            name="username"
                                            label="Никнейм пользователя"
                                            placeholder="Введите никнейм пользователя"
                                            variant="outlined"
                                            fullWidth

                                            onChange={handleChange}
                                        />
                                    </Box>

                                    <Box mb={2}>
                                        <TextField
                                            value={values.email}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                            name="email"
                                            label="Email пользователя"
                                            placeholder="Введите Email пользователя"
                                            variant="outlined"
                                            fullWidth

                                            onChange={handleChange}
                                        />
                                    </Box>

                                    <Box mb={2}>
                                        <InputMask
                                            mask="+7 (099) 999 - 99 - 99"
                                            value={values.phone}
                                            maskChar=" "
                                            onChange={handleChange}
                                            formatChars={{
                                                '0': '[489]',
                                                '9': '[0-9]',
                                            }}
                                        >
                                            {() => (
                                                <TextField
                                                    error={Boolean(touched.phone && errors.phone)}
                                                    helperText={touched.phone && errors.phone}
                                                    name="phone"
                                                    label="Номер телефона"
                                                    placeholder="+7 (999) 999 - 99 - 99"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        </InputMask>
                                    </Box>
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"

                                        onClick={handleCloseModal}
                                    >
                                        Отменить
                                    </Button>

                                    <Button
                                        variant="contained"

                                        onClick={handleSubmit}
                                    >
                                        Создать
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Введите Имя пользователя"),
    lastName: Yup.string().required("Введите Фамилию пользователя"),
    username: Yup.string().required("Введите Никнейм пользователя"),
    email: Yup.string().email("Некорректный ввод Email адреса").required("Введите Email пользователя"),
    phone: Yup.string().matches(regexp.phone, "Введите номер телефона в формате - +7 (999) 999 - 99 - 99").required('Введите номер телефона'),
});

export default DialogCreateDriver;