import React from "react";
import {
    Box,
    Link,
    Grid,
    Button,
    Tooltip,
    Typography, FormGroup, FormControlLabel, Checkbox
} from "@mui/material";
import {
    AutocompleteDepots,
    AutocompleteTransport,
    AutocompleteDriver
} from "../../../../../../components";
import {
    Notification,
    NotificationTypes,
} from "../../../../../../common/Notification";
import {formatPhone} from "../../../../../../helper/formatPhone";
import {useSelector} from "react-redux";

const ActionForm = (props) => {
    const {
        order,
        onTakeWork
    } = props;
    const userId = useSelector(state => state.global.user.id);
    const [isEdited, setEdited] = React.useState(false);
    const [depotId, setDepotId] = React.useState(undefined);
    const [transportId, setTransportId] = React.useState(undefined);
    const [driverId, setDriverId] = React.useState(undefined);
    const [expeditorIsTheDriver, setExpeditorIsTheDriver] = React.useState(undefined);

    React.useEffect(() => {
        setDepotId(order?.depotId || undefined);
        setTransportId(order?.vehicleId || undefined);
        setDriverId(order?.driverId || undefined);
        setExpeditorIsTheDriver((order?.driverId === order?.courierUserId) || false);
    }, []);

    React.useEffect(() => {
        if (['courierInstalled'].includes(order.deliveryStatus)) {
            setEdited(true);
        } else {
            setEdited(false);
        }
    }, [order]);

    const handleChangeDepot = ({target}) => {
        setDepotId(String(target.value))
    }
    const handleChangeTransport = ({target}) => {
        setTransportId(String(target.value))
    }
    const handleChangeDriver = ({target}) => {
        setDriverId(String(target.value))
    }

    const handleSubmit = async () => {
        if (typeof depotId === "undefined") {
            Notification({
                type: NotificationTypes.error,
                message: "Выберите базу"
            })

            return
        }
        if (typeof transportId === "undefined") {
            Notification({
                type: NotificationTypes.error,
                message: "Выберите автомобиль"
            })
            return;
        }
        if (!expeditorIsTheDriver) {
            if (typeof driverId === "undefined") {
                Notification({
                    type: NotificationTypes.error,
                    message: "Выберите водителя"
                })
                return
            }

            return await onTakeWork({
                depotId: Number(depotId),
                vehicleId: Number(transportId),
                driverId: Number(driverId)
            });
        }

        await onTakeWork({
            depotId: Number(depotId),
            vehicleId: Number(transportId),
        });
    }

    return (
        <Box>

            <Box mb={1}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="h3" lineHeight="32px">
                            Информация о базе и о водителе
                        </Typography>
                    </Grid>

                    {Boolean(!isEdited) && (
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{height: 32}}
                                disabled={!['loaded', 'courierInstalled'].includes(order.deliveryStatus)}

                                onClick={() => setEdited(true)}
                            >
                                Редактировать
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>

            {Boolean(isEdited) && (
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AutocompleteDepots
                                value={depotId}
                                onChange={handleChangeDepot}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteTransport
                                disabled={Boolean(typeof depotId === "undefined")}
                                value={transportId}
                                depotId={depotId}
                                driverId={driverId}
                                onChange={handleChangeTransport}
                            />
                        </Grid>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={expeditorIsTheDriver}

                                            onChange={setExpeditorIsTheDriver.bind(this, !expeditorIsTheDriver)}
                                        />
                                    }
                                    label="Указать себя водителем"
                                />
                            </FormGroup>
                        </Grid>
                        {!expeditorIsTheDriver && (
                            <Grid item xs={12}>
                                <AutocompleteDriver
                                    disabled={Boolean(typeof depotId === "undefined")}
                                    value={driverId}
                                    expeditorId={userId}
                                    onChange={handleChangeDriver}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        sx={{height: 60}}

                                        onClick={handleSubmit}
                                    >
                                        Редактировать
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="error"
                                        sx={{height: 40}}
                                        disabled={['courierInstalled'].includes(order.deliveryStatus)}

                                        onClick={() => setEdited(false)}
                                    >
                                        Отменить
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {Boolean(!isEdited) && (
                <Grid container>
                    <Grid item xs={12}>
                        <Grid item container spacing={1}>
                            <Grid item>
                                <Typography variant="subtitle1">
                                    База:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" color="#1A1A1A" fontWeight="600">
                                    {order?.depot?.address}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item container spacing={1}>
                            <Grid item>
                                <Typography variant="subtitle1">
                                    Транспорт:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2" color="#1A1A1A" fontWeight="600">
                                    {`${order?.vehicle?.make} ${order?.vehicle?.model} (${order?.vehicle?.licensePlate})`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item container spacing={1}>
                            <Grid item>
                                <Typography variant="subtitle1">
                                    Водитель:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Typography variant="subtitle2" color="#1A1A1A" fontWeight="600">
                                            {`${order?.driver?.lastName} ${order?.driver?.firstName}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2" color="primary" fontWeight="600">
                                            {formatPhone(order?.driver?.phone)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title="Написать в телеграмм" arrow>
                                            <Button
                                                href={`https://t.me/+${order?.driver?.phone}`}
                                                target="_blank"
                                                variant="contained"
                                                color="primary"
                                                sx={{
                                                    padding: "0 8px",
                                                    minHeight: "initial",
                                                    height: 24,
                                                    borderRadius: 0
                                                }}
                                            >
                                                Написать в телеграмм
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}

        </Box>
    );
}


export default ActionForm
