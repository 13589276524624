import React, {useState} from "react";
import {Box, Button, Collapse, Grid, Link, Tooltip, Typography} from "@mui/material";
import {deliveryTime} from "../../../../../../helper/deliveryTime";
import {convertorMeterToKilometer, convertorNumber} from "../../../../../../helper/convertor";
import {MapWithRoute} from "../../../../../../components";
import {phoneFormat} from "../../../../../../common/Formater";
import {makeStyles} from "@mui/styles";
import Map from "../../../../../../components/Map";
import moment from "moment";
import agent from "../../../../../../agent/agent";
import {userIsDriver} from "../../../../../../helper/userType";
import {LoadingButton} from "@mui/lab";

class InformationAddresses extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            distance: "",
        }
    }

    componentDidMount = async () => {
        await this.getDistance();
    };


    getDistance = async () => {
        const {order} = this.props;
        const firsLat = order.store.addressLat;
        const firstLong = order.store.addressLong;
        const secondLat = order.deliveryAddressLat;
        const secondLong = order.deliveryAddressLong;

        const {distanceInMeters} = await agent.get(`/deliveries/distance?firstLat=${firsLat}&firstLong=${firstLong}&secondLat=${secondLat}&secondLong=${secondLong}`)
            .then(res => res.data)
            .catch(err => 0);
        this.setState({
            distance: convertorNumber(convertorMeterToKilometer(distanceInMeters), 2, '.'),
        });
    };

    render() {
        const {
            order,
            settings,
            billOfLadings,
            isLoadingBillOfLading,
            isLoadingSendLinkToAccount,
            onGetBillOfLading,
            setIsOpenAddBillOfLadingPhotos,
            onSendLinkToAccount
        } = this.props;
        const {
            distance
        } = this.state;

        return (
            <Box>

                <Box mb={4}>
                    <InfoSection
                        order={order}
                        title="Информация об отправке груза"
                        address={order?.store?.address}
                        phone={order?.providerUser?.phone}
                        coords={[order?.store?.addressLat, order?.store?.addressLong]}
                        type="store"

                        showBillOfLading
                        billOfLadings={billOfLadings}
                        isLoadingBillOfLading={isLoadingBillOfLading}
                        onGetBillOfLading={onGetBillOfLading}
                        setIsOpenAddBillOfLadingPhotos={setIsOpenAddBillOfLadingPhotos.bind(this, true)}
                    />
                </Box>

                <Box>
                    <InfoSection
                        title="Информация о доставке груза"
                        order={order}
                        address={order?.deliveryAddress}
                        deliveryDate={order.deliveryDate}
                        deliveryTimespan={order.deliveryTimespan}
                        phone={order.purchaserUser.phone}
                        coords={[order?.deliveryAddressLat, order?.deliveryAddressLong]}
                        storeCoords={[order?.store?.addressLat, order?.store?.addressLong]}
                        type="purchaser"
                        deliveryPrice={order.finalDeliveryPrice}
                        distance={distance}

                        settings={settings}

                        onSendLinkToAccount={onSendLinkToAccount}
                        isLoadingSendLinkToAccount={isLoadingSendLinkToAccount}
                    />
                </Box>

            </Box>
        );
    }
}

const InfoSection = (props) => {
    const {
        order,
        title,
        address,
        deliveryDate,
        coords,
        storeCoords,
        phone,
        deliveryTimespan,
        settings,
        deliveryPrice,
        distance,

        showBillOfLading,
        billOfLadings,
        isLoadingBillOfLading,
        onGetBillOfLading,
        setIsOpenAddBillOfLadingPhotos,

        type,

        onSendLinkToAccount,
        isLoadingSendLinkToAccount,
    } = props;

    const classes = useStyles();
    const [isShowMap, setShowMap] = React.useState(false);
    const [isShowMapRoute, setIsShowMapRoute] = useState(false);
    const [billOfLadingAnchorEl, setBillOfLadingAnchorEl] = React.useState(null);
    const [isOpenBillOfLading, setIsOpenBillOfLading] = React.useState(null);

    const handleGetBillOfLading = async (event) => {
        setBillOfLadingAnchorEl(event.currentTarget);

        await onGetBillOfLading();

        setIsOpenBillOfLading(true);
    }

    const handleCloseBillOfLading = () => {
        setBillOfLadingAnchorEl(null);
        setIsOpenBillOfLading(false);
    };

    const _addressLabel = () => {
        if (type === "store") {
            return "Адрес склада"
        }
        if (type === "purchaser") {
            return "Адрес доставки"
        }
    }
    const _deliveryDate = () => {
        const date = moment(deliveryDate).format("DD.MM.YYYY");
        const timesDelivery = deliveryTime(settings);
        const timespan = timesDelivery.filter(time => time.value === deliveryTimespan);

        return `${date} (${timespan[0]?.label || "Время не указано"})`;
    }
    const _phoneLabel = () => {
        if (type === "store") {
            return "Телефон отправителя"
        }
        if (type === "purchaser") {
            return "Телефон получателя"
        }
    }
    const _isShowMap = () => {
        return Boolean(coords[0] && coords[1])
    }

    const _isShowMapRoute = () => {
        if (Boolean(!!distance)) {
            return Boolean(coords[0] && coords[1] && storeCoords[0] && storeCoords[1]);
        } else {
            return false;
        }
    };

    return (
        <Box>
            <Box mb={1}>
                <Typography variant="h3">
                    {title}
                </Typography>
            </Box>

            {Boolean(type === "purchaser") && (
                <Box>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant="subtitle1" fontWeight="600">
                                Срок доставки:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Доставить не позднее данного срока" arrow>
                                <Typography variant="subtitle2" color="#84b92c" fontWeight="600"
                                            sx={{textDecoration: "underline"}}>
                                    {_deliveryDate()}
                                </Typography>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
            )}

            <Box>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography variant="subtitle1">
                            {_addressLabel()}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="#1A1A1A" fontWeight="600">
                            {address || "Милицейский адрес не найден"}
                        </Typography>
                    </Grid>

                    {Boolean(_isShowMap()) && (
                        <Grid item>
                            <Button
                                variant="text"
                                color="primary"
                                sx={{padding: "0 8px", minHeight: "initial", height: 24, borderRadius: 0}}

                                onClick={() => setShowMap(!isShowMap)}
                            >
                                {isShowMap ? "Свернуть карту" : "Развернуть карту"}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Box>

            <Box>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography variant="subtitle1">
                            Широта:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="#1A1A1A" fontWeight="600">
                            {coords?.[0] || "-"}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography variant="subtitle1">
                            Долгота:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" color="#1A1A1A" fontWeight="600">
                            {coords?.[1] || "-"}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            {Boolean(!!distance) && (
                <Box>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant="subtitle1">
                                Расстояние:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2" color="#1A1A1A" fontWeight="600">
                                {distance} км
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {Boolean(_isShowMapRoute()) && (
                <Box>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant="subtitle1">
                                Маршрут:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                color="primary"
                                sx={{padding: "0 8px", minHeight: "initial", height: 24, borderRadius: 0}}

                                onClick={() => setIsShowMapRoute(!isShowMapRoute)}
                            >
                                {isShowMapRoute ? "Свернуть карту" : "Развернуть карту"}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )}

            <Box>
                <Grid container spacing={1}>
                    <Grid item>
                        <Typography variant="subtitle1">
                            {_phoneLabel()}:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Link className={classes.linkValue} href={`tel:+${phone}`}>{phoneFormat(phone)}</Link>
                    </Grid>
                    {Boolean(!!phone) && (
                        <Grid item>
                            <Tooltip title="Написать в телеграмм" arrow>
                                <Button
                                    href={`https://t.me/+${phone}`}
                                    target="_blank"
                                    variant="contained"
                                    color="primary"
                                    sx={{padding: "0 8px", minHeight: "initial", height: 24, borderRadius: 0}}
                                >
                                    Написать в телеграмм
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}

                    {(userIsDriver(order) && type === 'purchaser') && (
                        <Grid item>
                            <Tooltip title="Выслать ссылку на ЛК клиента" arrow>
                                <LoadingButton
                                    loading={isLoadingSendLinkToAccount}
                                    variant="contained"
                                    color="primary"
                                    sx={{padding: "0 8px", minHeight: "initial", height: 24, borderRadius: 0}}
                                    onClick={onSendLinkToAccount}
                                >
                                    Выслать ссылку на ЛК клиента
                                </LoadingButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Box>

            {(
                showBillOfLading &&
                order.deliveryStatus === 'received' &&
                userIsDriver(order) &&
                !order?.attachedPhotosFlags?.billOfLadingPhotos
            ) && (
                <Box>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant="subtitle1">
                                Накладные
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item>

                                    <Tooltip title="Прикрепить фотографии накладных на которых поставлены печати">
                                        <Button
                                            className={classes.button}
                                            variant="contained"
                                            color="primary"
                                            sx={{
                                                padding: "0 8px",
                                                minHeight: "initial",
                                                height: 30,
                                                borderRadius: 1,
                                            }}

                                            onClick={setIsOpenAddBillOfLadingPhotos}
                                        >
                                            Добавить
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {Boolean(!!deliveryPrice) && (
                <Box>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant="subtitle1">
                                Стоимость доставки:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2" color="#1A1A1A" fontWeight="600">
                                {deliveryPrice > 0 ? `${convertorNumber(deliveryPrice, 2, '.')} ₽` : `Бесплатно`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            )}


            {Boolean(_isShowMap()) && (
                <Collapse in={isShowMap} timeout="auto">
                    <Box height={420} mt={2}>
                        <Map
                            center={coords}
                            coords={coords}
                        />
                    </Box>
                </Collapse>
            )}

            {(Boolean(_isShowMapRoute())) && (
                <Collapse in={isShowMapRoute} timeout="auto">
                    <Box height={420} mt={2}>
                        <MapWithRoute
                            pointA={[storeCoords[0], storeCoords[1]]}
                            pointB={[coords[0], coords[1]]}
                            center={[coords[0], coords[1]]}
                        />
                    </Box>
                </Collapse>
            )}

        </Box>
    );
}

const useStyles = makeStyles({
    linkValue: {
        fontFamily: "CodeNext",
        fontFeatureSettings: "'ss03' on,'ss06' on",
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "22px",
        letterSpacing: "0.02em",
        margin: 0,
    },

    menuLinkButton: {
        fontWeight: 400,
        padding: "0 8px",
        minHeight: "initial",
        height: "auto",
        borderRadius: 1,
        color: "#000000",
    },
});


export default InformationAddresses
