import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    FormHelperText
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import { useFormik } from "formik";
import {
    Map
} from "../../../../../../components";
import React, { useCallback } from "react";
import * as Yup from "yup";

const initialValues = {
    name: "",
    description: "",
    address: "",
    coords: [null, null],
};

const AddStoreModal = ({ isOpen, handleClose, createStore }) => {
    const classes = useStyles();
    const onSubmitForm = useCallback(
        createStore(() => handleCloseModal(true)),
        []
    );

    const handleCloseModal = (withNewStores = false) => {
        handleClose(withNewStores);
        formik.handleReset();
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ValidationSchema,
        onSubmit: onSubmitForm,
    });

    const onSubmit = () => {
        formik.handleSubmit();
    };


    const handleChangeCoords = (coords) => {
        let newValues = {...formik.values};
        newValues.coords = coords;
        formik.setValues(newValues);
    }

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={() => handleCloseModal()}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    <Typography variant="h3">Создание склада</Typography>
                </DialogTitle>
                <DialogContent>
                    <form
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignContent: "center",
                        }}
                    >
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                name="name"
                                label="Название"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.name &&
                                    Boolean(formik.errors.name)
                                }
                                helperText={
                                    formik.touched.name && formik.errors.name
                                }
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                name="description"
                                label="Описание"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.description &&
                                    Boolean(formik.errors.description)
                                }
                                helperText={
                                    formik.touched.description &&
                                    formik.errors.description
                                }
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                name="address"
                                label="Адрес"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.address &&
                                    Boolean(formik.errors.address)
                                }
                                helperText={
                                    formik.touched.address &&
                                    formik.errors.address
                                }
                            />
                        </Box>


                        <Box className={classes.map}>
                            <Map
                                coords={formik.values.coords}
                                onChangeCoords={handleChangeCoords}
                            />

                            {Boolean(
                                formik.touched.coords &&
                                formik.errors.coords
                            ) && (
                                <FormHelperText variant="filled" error>
                                    Выберите метку на карте
                                </FormHelperText>
                            )}
                        </Box>

                        {Boolean(false) && (
                          <>
                              {formik.values.coords.map((val, index) => {
                                  return (
                                      <Box mb={2}>
                                          <TextField
                                              fullWidth
                                              name={`coords[${index}]`}
                                              type="number"
                                              label={
                                                  index === 0 ? "Широта" : "Долгота"
                                              }
                                              value={formik.values.coords[index]}
                                              onChange={formik.handleChange}
                                              error={
                                                  formik.touched.coords &&
                                                  formik.errors.coords &&
                                                  formik.touched.coords[index] &&
                                                  Boolean(formik.errors.coords[index])
                                              }
                                              helperText={
                                                  formik.touched.coords &&
                                                  formik.errors.coords &&
                                                  formik.touched.coords[index] &&
                                                  formik.errors.coords[index]
                                              }
                                          />
                                      </Box>
                                  );
                              })}
                          </>
                        )}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Box px={2}>
                        <Grid container justifyContent="flex-end" spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleCloseModal()}
                                    sx={{
                                        textTransform: "initial",
                                        borderRadius: "4px",
                                        padding: "4px 24px",
                                    }}
                                >
                                    Отменить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    onClick={onSubmit}
                                    sx={{
                                        textTransform: "initial",
                                        borderRadius: "4px",
                                        padding: "4px 24px",
                                    }}
                                >
                                    Создать
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
};

const useStyles = makeStyles(() => ({
    map: {
        height: 240
    }
}));

//добавление кортежа для валидации координат
Yup.addMethod(Yup.array, "tuple", function (schema) {
    if (!this.isType(schema)) Yup.ValidationError();
    return Yup.object({
        tuple: Yup.array().min(schema.length).max(schema.length),
        ...Object.fromEntries(Object.entries(schema)),
    }).transform((value, originalValue) => {
        if (!this.isType(originalValue)) Yup.ValidationError();
        return {
            tuple: originalValue,
            ...Object.fromEntries(Object.entries(originalValue)),
        };
    });
});

const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Обязательное поле"),
    description: Yup.string().required("Обязательное поле"),
    address: Yup.string().required("Обязательное поле"),
    coords: Yup.array().tuple([
        Yup.number()
            .required("Обязательное поле")
            .typeError("Обязательное поле")
            .min(-90, "Минимальное значение -90")
            .max(90, "Максимальное значение 90"),
        Yup.number()
            .required("Обязательное поле")
            .typeError("Обязательное поле")
            .min(-180, "Минимальное значение -180")
            .max(180, "Максимальное значение 180"),
    ]),
});

export default AddStoreModal;
