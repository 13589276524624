import React from "react";
import {
    Box,
    Grid,
    Typography,

    Table,
    TableBody,
    TableRow,
    TableCell,
    Link
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Map
} from "../../../../../../components";
import moment from "moment";
import paymentStatus from "../../../../../../constants/paymentStatus";
import deliveryStatus from "../../../../../../constants/deliveryStatus";
import {convertorNumber} from "../../../../../../helper/convertor";
import { Storefront } from "@mui/icons-material";

const Information = (props) => {
    const {
        store
    } = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant="h3" sx={{marginBottom: 1, fontWeight: "600"}}>Информация о складе</Typography>
            <Table className={classes.table}>
                <TableBody>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>Название склада</TableCell>
                        <TableCell className={classes.tableMessage}>{store.name || "—"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>Описание склада</TableCell>
                        <TableCell className={classes.tableMessage}>{store.description || "—"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>Резерв</TableCell>
                        <TableCell className={classes.tableMessage}>{store.reserve ? 'да' : 'нет'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>Адрес</TableCell>
                        <TableCell className={classes.tableMessage}>{store.address || "—"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>Дата создания</TableCell>
                        <TableCell className={classes.tableMessage}>{moment(store.deliveryDate).format("DD.MM.YYYY HH:mm")}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="25%" className={classes.tableLabel}>Местоположение</TableCell>
                        <TableCell className={classes.tableMessage}>
                          <Link underline="always" rel="noopener" target="_blank" href={`https://www.google.com/maps/@${store.addressLat},${store.addressLong},13z`}>На карте</Link>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Box className={classes.map}>
                <Map
                    coords={[store.addressLat, store.addressLong]}
                />
            </Box>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        border: "1px solid #EAEAEA",
        borderRadius: 8,
        padding: "16px 32px 24px"
    },
    table: {
        boxShadow: "none",
        borderRadius: 0
    },
    tableLabel: {
        "&.MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important",

            fontSize: 18,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "#464646"
        }
    },
    tableMessage: {
        "&.MuiTableCell-root": {
            padding: "8px 0 0 0",
            backgroundColor: "transparent!important",

            fontSize: 18,
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "black",
            fontWeight: "600"
        }
    },

    map: {
        marginTop: 40,
        height: 320
    }
}));

export default Information
