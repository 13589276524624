import React, {Component} from "react";
import {
    Box,
    Grid,
    Button,
    Typography,
    Backdrop,
    CircularProgress
} from "@mui/material";
import {
    Logout as LogoutIcon
} from "@mui/icons-material";
import {
    withStyles
} from "@mui/styles";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import {
    UserInfo as UserInfoComponent,
    DialogEditUser as DialogEditUserComponent
} from "./components";
import {
    DialogConfirmation
} from "../../../../components";
import agent from "../../../../agent/agent";

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenEditUser: false,
            isShowBackdrop: false,
        };

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = () => {
    };

    // Логика выхода из аккаунта
    logOutUser = () => {

        localStorage.removeItem('jwt');

        this.props.setUser(null);

    }

    // Логика редактирования данных пользователя
    editUser = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: "Вы действительно хотите изменить данные?",
                acceptButtonTitle: "Да, изменить",
                acceptButtonAction: this.editUser.bind(this, form, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.put(`/users`, form)
            .then(res => res.data.user)
            .catch(err => {
                return {error: err.response}
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при изменении данных",
                type: NotificationTypes.error,
            });

            return null;
        }

        this.props.setUser(response);

        this.setState({isShowBackdrop: false});

        Notification({
            message: "данные успешно изменены",
            type: NotificationTypes.success,
        });
    };

    // Открытие формы редактирования пользователя
    setIsOpenEditUser = (isOpen) => {
        this.setState({isOpenEditUser: isOpen});
    };
    // Закрытие формы редактирования пользователя
    handleCloseEditUser = () => {
        this.setIsOpenEditUser(false);
    };

    render() {
        const {
            user,
            classes,
        } = this.props;
        const {
            isOpenEditUser,
            isShowBackdrop
        } = this.state

        return (
            <Box mb={2}>
                <Box mb={4}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h1">Профиль</Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                endIcon={<LogoutIcon/>}
                                color="secondary"
                                variant="contained"
                                className={classes.buttonLogOut}

                                onClick={this.logOutUser}
                            >
                                Выход
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                <UserInfoComponent
                    user={user}
                    onOpenEditUser={this.setIsOpenEditUser}
                />

                <DialogEditUserComponent
                    user={user}
                    isOpen={isOpenEditUser}
                    onClose={this.handleCloseEditUser}
                    onEdit={this.editUser}
                />

                <DialogConfirmation
                    ref={this.refDialogConfirmation}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>

            </Box>
        );
    }
}

const styles = {
    buttonLogOut: {
        height: 40,
        padding: "0 32px"
    }
}
const StylesProfile = withStyles(styles)(Profile);

export default StylesProfile;
