import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    TextField,
    Typography
} from "@mui/material";
import {
    Formik
} from "formik";
import * as Yup from "yup";

const DialogEditUser = (props) => {
    const {
        user,
        isOpen,
        onClose,
        onEdit
    } = props;

    const refFormik = React.useRef(null);
    const initialValues = {...user};

    const onSubmit = (form) => {
        onEdit(form);
        handleCloseModal();
    };

    const handleChange = ({target}) => {
        const {name, value} = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleCloseModal = () => {
        refFormik.current.resetForm();
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"

            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">Изменение данных</Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1} mb={2}>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            value={values.lastName}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                            name="lastName"
                                            label="Фамилия"
                                            placeholder="Введите фамилия"
                                            variant="outlined"

                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            value={values.firstName}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                            name="firstName"
                                            label="Имя"
                                            placeholder="Введите имя"
                                            variant="outlined"

                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box mb={2}>
                                        <TextField
                                            fullWidth
                                            value={values.email}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                            name="email"
                                            label="Email"
                                            placeholder="Введите Email"
                                            variant="outlined"

                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            fullWidth
                                            value={values.username}
                                            error={Boolean(touched.username && errors.username)}
                                            helperText={touched.username && errors.username}
                                            name="username"
                                            label="Логин"
                                            placeholder="Введите логин"
                                            variant="outlined"

                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Box>

                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        onClick={handleCloseModal}
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px"
                                        }}
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            textTransform: "initial",
                                            borderRadius: "4px",
                                            padding: "4px 24px"
                                        }}

                                        onClick={handleSubmit}
                                    >
                                        Редактировать
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Введите имя"),
    // lastName: Yup.string().required("Введите фамилию"),
    // username: Yup.string().required("Введите логин"),
    // email: Yup.string().email("Некорректный ввод Email адреса").required("Введите Email"),
})

export default React.memo(DialogEditUser);
