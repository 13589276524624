import React from "react";
import {
    Box, Grid,
    Typography
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import moment from "moment";
import {AssignmentInd as AssignmentIndIcon} from "@mui/icons-material";

const AccreditationCard = (props) => {
    const {
        accreditation,
        categories
    } = props;
    const classes = useStyles();
    const accreditationCategoryName = (categories || []).find((t) => t.id === accreditation.categoryId)?.name || "";

    return (
        <Box className={classes.card}>

            <Grid container alignItems="center" justifyContent="space-between" sx={{marginBottom: 1}}>
                <Grid item>
                    <Typography className={classes.cardTitle}>
                        Аккредитация
                    </Typography>
                </Grid>
                <Grid item>
                    <Box display="flex" alignItems="center" justifyContent="center" width={32} height={32} borderRadius={999} bgcolor="rgb(132, 185, 44, 0.4)">
                        <AssignmentIndIcon fontSize="small" sx={{color: "#009012"}}/>
                    </Box>
                </Grid>
            </Grid>

            <Typography className={classes.cardName}>
                { accreditationCategoryName }
            </Typography>

            <Typography className={classes.cardDate}>
                От { moment(accreditation.createdAt).format("DD.MM.YYYY") }
            </Typography>

        </Box>
    )
}

const useStyles = makeStyles(() => ({
    card: {
        display: "flex",
        flexDirection: "column",

        height: "100%",
        padding: "18px 12px",
        boxSizing: "border-box",

        borderRadius: 8,
        border: "1px solid #EAEAEA"
    },
    cardTitle: {
        fontSize: 12,
        lineHeight: "130%",
        letterSpacing: "0.1px",
        color: "#909090"
    },
    cardName: {
        fontWeight: "bold",
        fontSize: 24,
        lineHeight: "28px",
        letterSpacing: "0.01em",
        color: "#000000",
        marginBottom: 18
    },
    cardDate: {
        fontSize: 12,
        lineHeight: "130%",
        letterSpacing: "0.1px",
        color: "#909090",

        marginTop: "auto"
    },
}));

export default AccreditationCard
