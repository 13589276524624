import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { Component } from "react";

import agent from "../../../../agent/agent";
import {
    InfoMessage as InfoMessageComponent,
    Information as InformationComponent,
} from "./components";

class MyStore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            store: {},

            storeId: props?.match?.params?.id || null,

            isLoading: true,
            isNotFound: false,
        };
    }

    componentDidMount = async () => {
        if (!this.state.storeId) {
            this.props.history.push(`/my-stores`);
        }
        await this.getStore();
    };

    // Логика получения склада
    getStore = async () => {
        const storeId = this.state.storeId;

        const store = await agent
            .get(`/stores/${storeId}`)
            .then((res) => {
                return res.data?.store || null;
            })
            .catch(() => {
                return null;
            });
        if (!store) {
            this.setState({
                isNotFound: true,
                isLoading: false,
            });

            return null;
        }

        this.setState({
            isLoading: false,
            store,
        });
    };

    render() {
        const { store, isLoading, isNotFound, storeId } = this.state;

        return (
            <>
                <Box mb={2}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <Grid item>
                            <Grid container alignItems="flex-end" spacing={1}>
                                <Grid item>
                                    <Typography variant="h1" textAlign="left">
                                        Склад № {storeId}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Статус заказа" arrow>
                                        <Typography
                                            variant="h3"
                                            sx={{ color: "#84b92c" }}
                                            fontWeight="600"
                                        >
                                            (новый)
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item>
                            <ActionsComponent/>
                        </Grid> */}
                    </Grid>
                </Box>

                {Boolean(!isLoading && !isNotFound) && (
                    <Box mb={2}>
                        <InformationComponent
                            store={store}
                        />
                    </Box>
                )}

                {Boolean(isLoading) && (
                    <InfoMessageComponent
                        message="Загружаем склад"
                        type="loading"
                    />
                )}

                {Boolean(isNotFound) && (
                    <InfoMessageComponent
                        message="Склад не найден"
                        type="warn"
                    />
                )}
            </>
        );
    }
}

export default MyStore;
