import React from "react";
import {
    Box,
    Grid,
    Button,
    Typography,

    FormControlLabel,
    FormGroup,
    Checkbox
} from "@mui/material";
import {
    AutocompleteDepots,
    AutocompleteTransport,
    AutocompleteDriver
} from "../../../../../../components";
import {
    Notification,
    NotificationTypes,
} from "../../../../../../common/Notification";
import {useSelector} from "react-redux";

const ActionForm = (props) => {
    const {
        onTakeWork
    } = props;
    const userId = useSelector(state => state.global.user.id);
    const [depotId, setDepotId] = React.useState(undefined);
    const [transportId, setTransportId] = React.useState(undefined);
    const [driverId, setDriverId] = React.useState(undefined);
    const [expeditorIsTheDriver, setExpeditorIsTheDriver] = React.useState(true);

    const handleChangeDepot = ({target}) => {
        setDepotId(String(target.value))
    }
    const handleChangeTransport = ({target}) => {
        setTransportId(String(target.value))
    }
    const handleChangeDriver = ({target}) => {
        setDriverId(String(target.value))
    }

    const handleSubmit = () => {
        if (typeof depotId === "undefined") {
            Notification({
                type: NotificationTypes.error,
                message: "Выберите базу"
            })

            return
        }
        if (typeof transportId === "undefined") {
            Notification({
                type: NotificationTypes.error,
                message: "Выберите автомобиль"
            })
            return;
        }
        if (!expeditorIsTheDriver) {
            if (typeof driverId === "undefined") {
                Notification({
                    type: NotificationTypes.error,
                    message: "Выберите водителя"
                })
                return
            }

            return onTakeWork({
                depotId: Number(depotId),
                vehicleId: Number(transportId),
                driverId: Number(driverId)
            });
        }

        onTakeWork({
            depotId: Number(depotId),
            vehicleId: Number(transportId),
        });
    }

    return (
        <Box>

            <Box mb={1}>
                <Typography variant="h3">
                    Взятие заказа в работу
                </Typography>
            </Box>

            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AutocompleteDepots
                            value={depotId}
                            onChange={handleChangeDepot}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteTransport
                            disabled={Boolean(typeof depotId === "undefined")}
                            value={transportId}
                            depotId={depotId}
                            driverId={driverId}
                            onChange={handleChangeTransport}
                        />
                    </Grid>
                    <Grid item>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={expeditorIsTheDriver}

                                        onChange={setExpeditorIsTheDriver.bind(this, !expeditorIsTheDriver)}
                                    />
                                }
                                label="Указать себя водителем"
                            />
                        </FormGroup>
                    </Grid>
                    {!expeditorIsTheDriver && (
                        <Grid item xs={12}>
                            <AutocompleteDriver
                                disabled={Boolean(typeof depotId === "undefined")}
                                value={driverId}
                                expeditorId={userId}
                                onChange={handleChangeDriver}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{height: 60}}

                            onClick={handleSubmit}
                        >
                            Взять заказ в работу
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}


export default ActionForm
