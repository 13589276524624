export default [
    {
        "id": "100100000014",
        "name": "RSB+ (Банк Русский Стандарт)",
    },
    {
        "id": "100000000202",
        "name": "Норвик Банк"
    },
    {
        "id": "100000000201",
        "name": "Банк Кремлевский"
    },
    {
        "id": "100000000200",
        "name": "АКБ СЛАВИЯ"
    },
    {
        "id": "100000000206",
        "name": "Томскпромстройбанк"
    },
    {
        "id": "100000000205",
        "name": "Банк Заречье"
    },
    {
        "id": "100000000204",
        "name": "Америкэн Экспресс Банк"
    },
    {
        "id": "100000000203",
        "name": "МЕЖДУНАРОДНЫЙ ФИНАНСОВЫЙ КЛУБ"
    },
    {
        "id": "100000000208",
        "name": "Северный Народный Банк"
    },
    {
        "id": "100000000207",
        "name": "Дойче банк"
    },
    {
        "id": "100000000059",
        "name": "Центр-инвест"
    },
    {
        "id": "100000000058",
        "name": "ВЛАДБИЗНЕСБАНК"
    },
    {
        "id": "100000000179",
        "name": "Возрождение Филиал БМ-Банка"
    },
    {
        "id": "100000000178",
        "name": "КВАНТ МОБАЙЛ БАНК"
    },
    {
        "id": "100000000057",
        "name": "VK Pay - РНКО Деньги.Мэйл.Ру"
    },
    {
        "id": "100000000056",
        "name": "КБ Хлынов"
    },
    {
        "id": "100000000177",
        "name": "НОВИКОМБАНК"
    },
    {
        "id": "100000000062",
        "name": "НОКССБАНК"
    },
    {
        "id": "100000000183",
        "name": "ГТ банк"
    },
    {
        "id": "100000000061",
        "name": "МОНЕТА"
    },
    {
        "id": "100000000182",
        "name": "Банк Объединенный капитал"
    },
    {
        "id": "100000000181",
        "name": "Автоторгбанк"
    },
    {
        "id": "100000000180",
        "name": "Кубаньторгбанк"
    },
    {
        "id": "100000000187",
        "name": "Банк РЕСО Кредит"
    },
    {
        "id": "100000000066",
        "name": "Земский банк"
    },
    {
        "id": "100000000065",
        "name": "ТОЧКА (ФК ОТКРЫТИЕ)"
    },
    {
        "id": "100000000064",
        "name": "Кредит Урал Банк"
    },
    {
        "id": "100000000185",
        "name": "Нацинвестпромбанк"
    },
    {
        "id": "100000000184",
        "name": "АКБ НРБанк"
    },
    {
        "id": "100000000069",
        "name": "СДМ-Банк"
    },
    {
        "id": "100000000189",
        "name": "ТАТСОЦБАНК"
    },
    {
        "id": "100000000067",
        "name": "Новый век"
    },
    {
        "id": "100000000194",
        "name": "РУСНАРБАНК"
    },
    {
        "id": "100000000072",
        "name": "Братский АНКБ"
    },
    {
        "id": "100000000193",
        "name": "КБ Стройлесбанк"
    },
    {
        "id": "100000000071",
        "name": "НС Банк"
    },
    {
        "id": "100000000192",
        "name": "Банк МБА МОСКВА"
    },
    {
        "id": "100000000070",
        "name": "Датабанк"
    },
    {
        "id": "100000000191",
        "name": "КБЭР Банк Казани"
    },
    {
        "id": "100000000198",
        "name": "Экономбанк"
    },
    {
        "id": "100000000197",
        "name": "Трансстройбанк"
    },
    {
        "id": "100000000196",
        "name": "Инбанк"
    },
    {
        "id": "100000000195",
        "name": "Кузнецкбизнесбанк"
    },
    {
        "id": "100000000199",
        "name": "ИШБАНК"
    },
    {
        "id": "100000000078",
        "name": "Банк СОЮЗ"
    },
    {
        "id": "100000000080",
        "name": "Алмазэргиэнбанк"
    },
    {
        "id": "100000000084",
        "name": "РосДорБанк"
    },
    {
        "id": "100000000083",
        "name": "Дальневосточный банк"
    },
    {
        "id": "100000000082",
        "name": "Банк ДОМ.РФ"
    },
    {
        "id": "100000000081",
        "name": "Форштадт"
    },
    {
        "id": "100000000088",
        "name": "СКБ Примсоцбанк"
    },
    {
        "id": "100000000087",
        "name": "Банк ПСКБ"
    },
    {
        "id": "100000000086",
        "name": "ЭЛПЛАТ"
    },
    {
        "id": "100000000089",
        "name": "КБ КОЛЬЦО УРАЛА"
    },
    {
        "id": "100000000091",
        "name": "БАНК СНГБ"
    },
    {
        "id": "100000000090",
        "name": "Банк Екатеринбург"
    },
    {
        "id": "100000000095",
        "name": "АБ РОССИЯ"
    },
    {
        "id": "100000000094",
        "name": "ЧЕЛЯБИНВЕСТБАНК"
    },
    {
        "id": "100000000093",
        "name": "Углеметбанк"
    },
    {
        "id": "100000000092",
        "name": "БыстроБанк"
    },
    {
        "id": "100000000099",
        "name": "КБ Модульбанк"
    },
    {
        "id": "100000000098",
        "name": "КБ  РостФинанс"
    },
    {
        "id": "100000000015",
        "name": "Банк ФК Открытие"
    },
    {
        "id": "100000000136",
        "name": "МЕТКОМБАНК"
    },
    {
        "id": "100000000014",
        "name": "Банк Русский Стандарт"
    },
    {
        "id": "100000000135",
        "name": "Банк Акцепт"
    },
    {
        "id": "100000000256",
        "name": "Байкалкредобанк"
    },
    {
        "id": "100000000013",
        "name": "Совкомбанк"
    },
    {
        "id": "100000000134",
        "name": "НБД-Банк"
    },
    {
        "id": "100000000255",
        "name": "Банк ПТБ"
    },
    {
        "id": "100000000012",
        "name": "Росбанк"
    },
    {
        "id": "100000000133",
        "name": "ББР Банк"
    },
    {
        "id": "100000000139",
        "name": "КБ ЭНЕРГОТРАНСБАНК"
    },
    {
        "id": "100000000018",
        "name": "ОТП Банк"
    },
    {
        "id": "100000000017",
        "name": "МТС-Банк"
    },
    {
        "id": "100000000138",
        "name": "Тойота Банк"
    },
    {
        "id": "100000000016",
        "name": "Почта Банк"
    },
    {
        "id": "100000000258",
        "name": "АИКБ Енисейский объединенный банк"
    },
    {
        "id": "100000000022",
        "name": "ЮМани"
    },
    {
        "id": "100000000143",
        "name": "Банк 131"
    },
    {
        "id": "100000000142",
        "name": "УРАЛПРОМБАНК"
    },
    {
        "id": "100000000020",
        "name": "Россельхозбанк"
    },
    {
        "id": "100000000140",
        "name": "МБ Банк"
    },
    {
        "id": "100000000026",
        "name": "БАНК УРАЛСИБ"
    },
    {
        "id": "100000000147",
        "name": "САРОВБИЗНЕСБАНК"
    },
    {
        "id": "100000000025",
        "name": "Московский Кредитный Банк"
    },
    {
        "id": "100000000146",
        "name": "КОШЕЛЕВ-БАНК"
    },
    {
        "id": "100000000024",
        "name": "Хоум Кредит Банк"
    },
    {
        "id": "100000000144",
        "name": "Тимер Банк"
    },
    {
        "id": "100000000029",
        "name": "Банк Санкт-Петербург"
    },
    {
        "id": "100000000028",
        "name": "Банк АВАНГАРД"
    },
    {
        "id": "100000000149",
        "name": "ГУТА-БАНК"
    },
    {
        "id": "100000000027",
        "name": "Кредит Европа Банк (Россия)"
    },
    {
        "id": "100000000148",
        "name": "СИНКО-БАНК"
    },
    {
        "id": "100000000150",
        "name": "КБ АКРОПОЛЬ"
    },
    {
        "id": "100000000154",
        "name": "Банк Аверс"
    },
    {
        "id": "100000000033",
        "name": "МИнБанк"
    },
    {
        "id": "100000000153",
        "name": "Банк Венец"
    },
    {
        "id": "100000000032",
        "name": "Ренессанс Кредит"
    },
    {
        "id": "100000000031",
        "name": "УБРиР"
    },
    {
        "id": "100000000152",
        "name": "Тольяттихимбанк"
    },
    {
        "id": "100000000030",
        "name": "ЮниКредит Банк"
    },
    {
        "id": "100000000151",
        "name": "Урал ФД"
    },
    {
        "id": "100000000037",
        "name": "ГЕНБАНК"
    },
    {
        "id": "100000000158",
        "name": "Банк ИТУРУП"
    },
    {
        "id": "100000000036",
        "name": "СМП Банк"
    },
    {
        "id": "100000000035",
        "name": "Запсибкомбанк"
    },
    {
        "id": "100000000034",
        "name": "ТРАНСКАПИТАЛБАНК"
    },
    {
        "id": "100000000159",
        "name": "Энергобанк"
    },
    {
        "id": "100000000040",
        "name": "Банк ФИНАМ"
    },
    {
        "id": "100000000161",
        "name": "КБ ЛОКО-Банк"
    },
    {
        "id": "100000000160",
        "name": "ЮГ-Инвестбанк"
    },
    {
        "id": "100000000044",
        "name": "Экспобанк"
    },
    {
        "id": "100000000165",
        "name": "Русьуниверсалбанк"
    },
    {
        "id": "100000000043",
        "name": "Газэнергобанк"
    },
    {
        "id": "100000000164",
        "name": "КЭБ БАНК РУС"
    },
    {
        "id": "100000000163",
        "name": "Банк Снежинский"
    },
    {
        "id": "100000000042",
        "name": "ЮНИСТРИМ БАНК"
    },
    {
        "id": "100000000041",
        "name": "БКС Банк"
    },
    {
        "id": "100000000048",
        "name": "КБ ПЛАТИНА"
    },
    {
        "id": "100000000169",
        "name": "Банк СКС"
    },
    {
        "id": "100000000047",
        "name": "Абсолют Банк"
    },
    {
        "id": "100000000046",
        "name": "Металлинвестбанк"
    },
    {
        "id": "100000000167",
        "name": "АКБ ЕВРОФИНАНС МОСНАРБАНК"
    },
    {
        "id": "100000000045",
        "name": "Банк ЗЕНИТ"
    },
    {
        "id": "100000000166",
        "name": "СИБСОЦБАНК"
    },
    {
        "id": "100000000049",
        "name": "Банк ВБРР"
    },
    {
        "id": "100000000172",
        "name": "Банк Развитие-Столица"
    },
    {
        "id": "100000000050",
        "name": "Кубань Кредит"
    },
    {
        "id": "100000000171",
        "name": "МОРСКОЙ БАНК"
    },
    {
        "id": "100000000170",
        "name": "Банк Интеза"
    },
    {
        "id": "100000000176",
        "name": "МОСКОМБАНК"
    },
    {
        "id": "100000000055",
        "name": "Банк Йошкар-Ола"
    },
    {
        "id": "100000000054",
        "name": "WebMoney (Банк ККБ)"
    },
    {
        "id": "100000000175",
        "name": "АКБ Тендер Банк"
    },
    {
        "id": "100000000053",
        "name": "Бланк банк"
    },
    {
        "id": "100000000174",
        "name": "Первый Инвестиционный Банк"
    },
    {
        "id": "100000000052",
        "name": "Банк Левобережный"
    },
    {
        "id": "100000000173",
        "name": "Таврический Банк"
    },
    {
        "id": "100000000213",
        "name": "Джей энд Ти Банк (АО)"
    },
    {
        "id": "100000000212",
        "name": "КБ Крокус Банк"
    },
    {
        "id": "100000000211",
        "name": "Банк АЛЕКСАНДРОВСКИЙ"
    },
    {
        "id": "100000000210",
        "name": "БАЛТИНВЕСТБАНК"
    },
    {
        "id": "100000000217",
        "name": "ФОРА-БАНК"
    },
    {
        "id": "100000000216",
        "name": "Банк Финсервис"
    },
    {
        "id": "100000000215",
        "name": "ВУЗ банк"
    },
    {
        "id": "100000000219",
        "name": "БАНК СГБ"
    },
    {
        "id": "100000000218",
        "name": "Чайна Констракшн Банк"
    },
    {
        "id": "100000000220",
        "name": "КБ Восточный"
    },
    {
        "id": "100000000103",
        "name": "КБ Пойдём"
    },
    {
        "id": "100000000102",
        "name": "Банк Агророс"
    },
    {
        "id": "100000000223",
        "name": "СОЦИУМ БАНК"
    },
    {
        "id": "100000000222",
        "name": "Новобанк"
    },
    {
        "id": "100000000221",
        "name": "МОСОБЛБАНК"
    },
    {
        "id": "100000000107",
        "name": "АКИБАНК"
    },
    {
        "id": "100000000228",
        "name": "Прио-Внешторгбанк"
    },
    {
        "id": "100000000106",
        "name": "ЧЕЛИНДБАНК"
    },
    {
        "id": "100000000227",
        "name": "Банк БКФ"
    },
    {
        "id": "100000000105",
        "name": "Эс-Би-Ай Банк"
    },
    {
        "id": "100000000226",
        "name": "Банк Приморье"
    },
    {
        "id": "100000000225",
        "name": "УКБ Белгородсоцбанк"
    },
    {
        "id": "100000000109",
        "name": "НКО ЕРП"
    },
    {
        "id": "100000000229",
        "name": "МС Банк Рус"
    },
    {
        "id": "100000000108",
        "name": "Азиатско Тихоокеанский Банк"
    },
    {
        "id": "100000000110",
        "name": "КБ Москоммерцбанк"
    },
    {
        "id": "100000000231",
        "name": "Банк ЦентроКредит"
    },
    {
        "id": "100000000230",
        "name": "Солид Банк"
    },
    {
        "id": "100000000235",
        "name": "АКБ Держава"
    },
    {
        "id": "100000000113",
        "name": "Алеф-Банк"
    },
    {
        "id": "100000000234",
        "name": "БАНК МОСКВА СИТИ"
    },
    {
        "id": "100000000112",
        "name": "ГАРАНТ-ИНВЕСТ БАНК"
    },
    {
        "id": "100000000233",
        "name": "НК Банк"
    },
    {
        "id": "100000000111",
        "name": "Сбербанк"
    },
    {
        "id": "100000000232",
        "name": "Реалист Банк"
    },
    {
        "id": "100000000118",
        "name": "КБ АГРОПРОМКРЕДИТ"
    },
    {
        "id": "100000000239",
        "name": "Индустриальный Сберегательный Банк"
    },
    {
        "id": "100000000117",
        "name": "ПроБанк"
    },
    {
        "id": "100000000115",
        "name": "НИКО-БАНК"
    },
    {
        "id": "100000000236",
        "name": "Банк ИПБ"
    },
    {
        "id": "100000000121",
        "name": "КБ Солидарность"
    },
    {
        "id": "100000000242",
        "name": "ИНГ БАНК (ЕВРАЗИЯ)"
    },
    {
        "id": "100000000241",
        "name": "Национальный расчетный депозитарий"
    },
    {
        "id": "100000000240",
        "name": "РГС Банк"
    },
    {
        "id": "100000000004",
        "name": "Тинькофф Банк"
    },
    {
        "id": "100000000125",
        "name": "ГОРБАНК"
    },
    {
        "id": "100000000246",
        "name": "МСП Банк"
    },
    {
        "id": "100000000003",
        "name": "СКБ-банк"
    },
    {
        "id": "100000000124",
        "name": "БАНК ОРЕНБУРГ"
    },
    {
        "id": "100000000245",
        "name": "АКБ Ланта Банк"
    },
    {
        "id": "100000000002",
        "name": "Золотая Корона (РНКО Платежный Центр)"
    },
    {
        "id": "100000000123",
        "name": "НКО МОБИ.Деньги"
    },
    {
        "id": "100000000001",
        "name": "Газпромбанк"
    },
    {
        "id": "100000000243",
        "name": "Банк Национальный стандарт"
    },
    {
        "id": "100000000122",
        "name": "ИК Банк"
    },
    {
        "id": "100000000008",
        "name": "Альфа Банк"
    },
    {
        "id": "100000000129",
        "name": "КБ АРЕСБАНК"
    },
    {
        "id": "100000000007",
        "name": "Райффайзенбанк"
    },
    {
        "id": "100000000128",
        "name": "Ситибанк"
    },
    {
        "id": "100000000006",
        "name": "Ак Барс Банк"
    },
    {
        "id": "100000000127",
        "name": "Хакасский муниципальный банк"
    },
    {
        "id": "100000000248",
        "name": "ВНЕШФИНБАНК"
    },
    {
        "id": "100000000005",
        "name": "ВТБ"
    },
    {
        "id": "100000000126",
        "name": "Банк Саратов"
    },
    {
        "id": "100000000247",
        "name": "банк Раунд"
    },
    {
        "id": "100000000009",
        "name": "QIWI Кошелек (КИВИ Банк)"
    },
    {
        "id": "100000000011",
        "name": "РНКБ Банк"
    },
    {
        "id": "100000000010",
        "name": "Промсвязьбанк"
    },
    {
        "id": "100000000130",
        "name": "Автоградбанк"
    },
    {
        "id": "100000000250",
        "name": "Сетелем Банк"
    }
]