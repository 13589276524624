// @flow
import MyStores from './MyStores';
import { connect } from 'react-redux';
import { compose } from 'recompose';

export default compose(
  connect(
    state => ({}),
    dispatch => ({}),
  ),
)(MyStores);