import React from "react";
import {
    Box,
    Grid,

    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,

    Pagination,

    Tooltip,
    IconButton,
} from "@mui/material";
import {
    Delete as DeleteIcon,
} from "@mui/icons-material";
import {
    makeStyles
} from "@mui/styles";
import {
    formatPhone
} from "../../../../../../helper/formatPhone";

const TableCustom = (props) => {
    const {
        data,
        pagination,

        isLoading,

        onChangePagination,
        onDeleteDriver
    } = props;

    const classes = useStyles();

    const handleChangePage = (event, page) => {
        let newPagination = {...pagination};
        newPagination.page = page;

        onChangePagination(newPagination)
    }

    return (
        <>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell>Фамилия</TableCell>
                        <TableCell>Имя</TableCell>
                        <TableCell>Номер телефона</TableCell>
                        <TableCell align="right"/>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        Boolean(isLoading) ? (

                            <>

                                {
                                    [0, 1, 2, 3, 4, 5].map((item) => (

                                        <TableRow key={`row-item-load-${item}`}>

                                        </TableRow>

                                    ))
                                }

                            </>

                        ) : (

                            <>

                                {

                                    data.map((row, index) => (
                                        <TableRow key={`table-row-${ index }`}>
                                            <TableCell>{ row.id }</TableCell>
                                            <TableCell>{ row.lastName }</TableCell>
                                            <TableCell>{ row.firstName }</TableCell>
                                            <TableCell>{ formatPhone(row.phone) }</TableCell>
                                            <TableCell align="right">
                                                <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                                                    <Grid item>
                                                        <Tooltip
                                                            title="Удалить"
                                                            classes={{
                                                                popper: classes.deleteTooltip,
                                                            }}
                                                        >
                                                            <IconButton
                                                                color="error"

                                                                onClick={onDeleteDriver.bind(this, row.id, false)}
                                                            >
                                                                <DeleteIcon color="error"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))

                                }

                            </>

                        )

                    }

                </TableBody>

            </Table>

            <Box mt={3}/>

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}

                        onChange={handleChangePage}
                    />
                </Grid>
            </Grid>

        </>
    )
}

const useStyles = makeStyles(() => ({
    deleteTooltip: {
        "&.MuiTooltip-popper": {
            '& .MuiTooltip-tooltip': {
                background: "#e53935",
            }
        }
    },
}));

export default TableCustom
