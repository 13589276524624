import React from "react";
import {
    Box,
    Typography,

    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import {} from "@mui/styles";
import {formatPhone} from "../../helper/formatPhone";

const TableDriver = (props) => {
    const {
        data
    } = props;

    return (
        <>

            <Typography variant="h5">
                Водители
            </Typography>

            <Box mt={1}/>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell>Фамилия</TableCell>
                        <TableCell>Имя</TableCell>
                        <TableCell>Номер телефона</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(data || []).map((row, index) => (
                        <TableRow key={`table-row-${ index }`}>
                            <TableCell>{ row.id }</TableCell>
                            <TableCell>{ row.lastName }</TableCell>
                            <TableCell>{ row.firstName }</TableCell>
                            <TableCell>{ formatPhone(row.phone) }</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        </>
    )
}

export default TableDriver
