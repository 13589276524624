import React, {useEffect} from 'react';
import {
    makeStyles
} from "@mui/styles";
import {
    AddressSuggestions
} from "react-dadata";
import {
    Box,
    Typography,

    FormHelperText,
    InputLabel,
    TextField
} from "@mui/material";
import {
    IMaskInput
} from "react-imask";
import {
    getAddressValue
} from "../../helper/addressSuggestionsHelpers";
import Map from "../Map";

let timeout = null;

const AddressSuggestionsComponent = (
    {
        addressValue,
        coordsValue,
        longitudeValue,
        latitudeValue,

        errors,
        touched,

        onChangeAddress,
        onResetAddress
    }
) => {

    const classes = useStyles();
    const filterLocationsBoost = [{"kladr_id": "66"}];
    const [address, setAddress] = React.useState(addressValue);
    const [coords, setCoords] = React.useState(coordsValue);
    const [latitude, setLatitude] = React.useState(latitudeValue);
    const [longitude, setLongitude] = React.useState(longitudeValue);
    const [isLatLong, setIsLatLong] = React.useState(false);

    const handleChangeAddress = (value) => {
        setCoords([+value.data.geo_lat, +value.data.geo_lon]);
        setLatitude(value.data.geo_lat + "");
        setLongitude(value.data.geo_lon + "");
        setIsLatLong(false);

        const newValue = {
            ...value,
            value: value?.value || "Милицейский адрес не найден",
        };

        onChangeAddress(newValue, [+value.data.geo_lat, +value.data.geo_lon]);
    };

    const handleChangeCoords = async (coords) => {
        setCoords(coords);
        setLatitude(coords[0] + "");
        setLongitude(coords[1] + "");
        setIsLatLong(true);
    };

    const handleChangeLatLong = async ({target}) => {
        const {name, value} = target;

        if (name === 'latitude') {
            await setLatitude(value + "");
        }

        if (name === 'longitude') {
            await setLongitude(value + "");
        }

        setIsLatLong(true);
    };

    useEffect(() => {
        if (isLatLong) {
            if (latitude.length > 5 && longitude.length > 5) {
                clearTimeout(timeout);

                timeout = setTimeout(async () => {
                    const value = await getAddressValue([+latitude, +longitude]);

                    setCoords([+latitude, +longitude]);
                    setAddress(value);
                    setIsLatLong(false);

                    onChangeAddress(value, coords);
                }, 1000);
            }
            if (latitude.length <= 0 || longitude.length <= 0) {
                clearTimeout(timeout);

                setCoords(["", ""]);
                setAddress({value: ''});
                setIsLatLong(false);

                onResetAddress();
            }
        }
    }, [latitude, longitude, isLatLong]);

    return (
        <Box>
            <Box mb={2}>
                <InputLabel sx={{marginBottom: 1}}>Введите адрес</InputLabel>
                <AddressSuggestions
                    token={process.env.REACT_APP_API_KEY_DADATA}
                    value={address}
                    filterLocationsBoost={filterLocationsBoost}
                    delay={1500}
                    minChars={5}

                    containerClassName={classes.dadata_container}
                    suggestionsClassName={classes.dadata_suggestions}
                    suggestionClassName={classes.dadata_suggestion}
                    highlightClassName={classes.dadata_highlighted}

                    onChange={handleChangeAddress}
                />
                {Boolean(touched?.address && errors?.address) && (
                    <FormHelperText variant="filled" error>
                        {touched?.address?.value && errors?.address?.value}
                    </FormHelperText>
                )}
            </Box>
            <Typography mb={1}>Введите координаты</Typography>
            <Box className={classes.rowContent} mb={2} sx={{gap: "8px"}}>
                <TextField
                    fullWidth
                    size="small"
                    color="primary"
                    value={latitude + ''}
                    name="latitude"
                    label="Широта"
                    error={touched?.latitude && Boolean(errors?.latitude)}
                    helperText={touched?.latitude && errors?.latitude}
                    InputProps={{
                        inputComponent: CustomInputAmount
                    }}
                    inputProps={{
                        scale: 10
                    }}
                    InputLabelProps={{
                        shrink: latitude !== "",
                    }}

                    onChange={handleChangeLatLong}
                />
                <TextField
                    fullWidth
                    size="small"
                    color="primary"
                    value={longitude + ''}
                    name="longitude"
                    label="Долгота"
                    error={touched?.longitude && Boolean(errors?.longitude)}
                    helperText={touched?.longitude && errors?.longitude}
                    InputProps={{
                        inputComponent: CustomInputAmount
                    }}
                    inputProps={{
                        scale: 10
                    }}
                    InputLabelProps={{
                        shrink: longitude !== "",
                    }}

                    onChange={handleChangeLatLong}
                />
            </Box>
            <Box mb={5} height="300px">
                <Typography mb={1} variant="subtitle1">
                    Двигайте карту, чтобы указать расположение для доставки:
                </Typography>

                <Map
                    coords={coords}
                    onChangeCoords={handleChangeCoords}
                />

                {Boolean(touched?.coords && errors?.coords) && (
                    <FormHelperText variant="filled" error>
                        Выберите метку на карте
                    </FormHelperText>)}
            </Box>
        </Box>
    );
};

const CustomInputAmount = ({inputRef, ...rest}) => (
    <IMaskInput
        ref={inputRef}
        {...rest}

        mask={Number}
        thousandsSeparator=""
        radix="."
        mapToRadix={[',']}
        scale={rest?.scale || 0}
        unmask={true}
    />
)

const useStyles = makeStyles({

    rowContent: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    dadata_container: {
        width: "100%",
        height: "auto",
        position: 'relative',
        '& div': {
            width: "100%",
            height: "100%",
            '& input': {
                display: "block",
                boxSizing: "border-box",
                height: "100%",
                border: "2px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                fontSize: "16px",
                padding: "16.5px 14px",
                outline: "none",
                "&:focus": {
                    borderColor: "#84b92c",
                }
            }
        },
    },
    dadata_suggestions: {
        position: "absolute",
        listStyle: "none",
        padding: "0",
        margin: "0",
        zIndex: "10000",
        backgroundColor: "#fff",
        boxShadow: "0 1px 6px 3px rgba(0,0,0,.1)",
        top: "100%",
        borderRadius: "4px",
        overflow: "hidden",
        left: "0",
        right: "0",
        textAlign: "left",
    },
    dadata_suggestion: {
        fontSize: "15px",
        padding: "7px 10px",
        cursor: "pointer",
        boxSizing: "border-box",
        width: "100%",
        display: "block",
        background: "none",
        border: "none",
        textAlign: "left",
        "&:hover": {
            backgroundColor: "rgba(132,185,44, 0.1)"
        },
    },
    dadata_highlighted: {
        color: "#84b92c",
    }
})

export default React.memo(AddressSuggestionsComponent);