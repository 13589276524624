import agent from "../agent/agent";

const GET_STORES = "stores/GET_STORES";

const initState = {
    stores: [],
};


export function getStores () {
    return async function (dispatch) {
        const stores = await agent.get(`/stores?listing=true`).then((res) => {
            return res.data.stores
        }).catch(() => {
            return []
        })

        dispatch({
            type: GET_STORES,
            stores
        })
    }
}

export default function StoresState(state = initState, action = {}) {
    switch (action.type) {
        case GET_STORES: {
            return {
                ...state,
                stores: action.stores
            }
        }
        default:
            return state;
    }
}
