import React from "react";
import {
    Box,
    Grid,
    Button,
    TextField,
    Typography,
} from "@mui/material";
import {
    Notification,
    NotificationTypes
} from "../../common/Notification";
import AutocompleteTransport from "../autocomplete/AutocompleteTransport"

const FormTransport = (props) => {
    const {
        isCreated,
        onSubmit
    } = props;
    const [ transportId, setTransportId ] = React.useState(undefined);

    const handleChangeDriver = ({ target }) => {
        const { value } = target;

        setTransportId(value)
    }
    const handleSubmit = () => {
        if (typeof transportId === "undefined") {
            Notification({
                type: NotificationTypes.error,
                message: "Выберите автомобиль"
            })

            return null
        }

        onSubmit(transportId, handleClearForm)
    }

    const handleClearForm = () => {
        setTransportId(undefined)
    }

    return (
        <Box>

            <Typography variant="h5">Форма создания пары</Typography>

            <Box mt={2}/>

            <AutocompleteTransport
                value={transportId}
                onChange={handleChangeDriver}
            />

            <Box mt={2}/>

            <Button
                variant="contained"
                fullWidth
                disabled={isCreated}
                onClick={handleSubmit}
            >
                {isCreated ? "Создаем связь..." : "Добавить связь"}
            </Button>

        </Box>
    )
}

export default FormTransport
