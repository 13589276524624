// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import ListOrders from './ListOrders';

export default compose(
  connect(
    state => ({
      stores: state?.stores?.stores || []
    }),
    dispatch => ({}),
  ),
)(ListOrders);
