import React from "react";
import {Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {numberFormat} from "../../../../../../common/Formater";
import {convertorNumber, convertorToPiece} from "../../../../../../helper/convertor";

const InformationProducts = (props) => {
    const {
        products,
        returnItems,
        shortageItems,
    } = props;
    const classes = useStyles();

    const renderProductQuantity = (product) => {
        const piece = convertorToPiece(product.quantity, product?.product?.pieceToCbmConversionFactor || 0);
        return (
            <>
                <Typography>{piece} шт.</Typography>
                <Typography>{numberFormat(product.quantity, 4)} м³</Typography>
            </>
        );
    };

    const renderShortageItemsPrice = () => {
        const totalPrice = shortageItems.reduce((acc, curr) => +acc + +curr.sum, 0);

        return (
            <Typography fontWeight="600" fontSize={18}>
                {convertorNumber(totalPrice, 2)} ₽
            </Typography>
        );
    };

    return (
        <Box>

            <Box mb={2}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <Typography variant="h3">
                            Состав заказа
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell width={64}>№</TableCell>
                        <TableCell width="32%">Наименование товара</TableCell>
                        <TableCell>Количество</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((product, index) => (
                        <TableRow key={`row-${index}`}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                                {product.productName}
                            </TableCell>
                            <TableCell>
                                {renderProductQuantity(product)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {returnItems.length > 0 && (
                <>
                    <Typography className={classes.title} pl={1} mt={2} mb={2}>Возврат</Typography>

                    <Table className={classes.table}>

                        <TableHead>
                            <TableRow>
                                <TableCell width={64}>№</TableCell>
                                <TableCell width="32%">Наименование товара</TableCell>
                                <TableCell>Количество</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(returnItems || []).map((product, index) => {
                                return (
                                    <TableRow key={`return-product-${product.id}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{product.product.name}</TableCell>
                                        <TableCell>
                                            {renderProductQuantity(product)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </>
            )}

            {shortageItems.length > 0 && (
                <>
                    <Typography className={classes.title} pl={1} mt={2} mb={2}>Недовоз</Typography>

                    <Table className={classes.table}>

                        <TableHead>
                            <TableRow>
                                <TableCell width={64}>№</TableCell>
                                <TableCell width="32%">Наименование товара</TableCell>
                                <TableCell>Количество</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(shortageItems || []).map((product, index) => {
                                return (
                                    <TableRow key={`shortage-product-${product.id}`}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{product.product.name}</TableCell>
                                        <TableCell>
                                            {renderProductQuantity(product)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>

                    <Table className={classes.table}>
                        <TableBody>
                            <TableRow>
                                <TableCell width="65%"/>
                                <TableCell>
                                    <Typography fontWeight="600" fontSize={18}>
                                        Стоимость недовоза:
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    {renderShortageItemsPrice()}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            )}
        </Box>
    );
}

const useStyles = makeStyles(() => ({
    table: {
        "& tr th": {
            fontSize: 12,
            opacity: 0.8,
            color: "black",
            fontWeight: "400",
            backgroundColor: "transparent",
            border: "none",
            padding: "8px 20px"
        },
        "& tr td": {
            fontSize: 14,
            color: "black",
            borderBottom: "8px solid rgb(248, 248, 248)",
            fontWeight: "400",
            backgroundColor: "white",

            "&:first-child": {
                borderRadius: "12px 0 0 12px!important"
            },
            "&:last-child": {
                borderRadius: "0 12px 12px 0!important"
            },
        },
    },

    title: {
        fontSize: 20,
        lineHeight: "24px",
        color: "black",
        fontWeight: "500"
    },
}));

export default InformationProducts
