const userIsLegal = (user) => {
    return !!user.organization;
};

const userIsDriver = (order) => {
    return order.courierUserId === order.driverId;
};

export {
    userIsLegal,
    userIsDriver
};