import React from 'react';
import {
    Box,

    IconButton,

    Link,

    Tooltip,
    Typography
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    HelpOutlineRounded as HelpIcon,
} from "@mui/icons-material";
import {
    phoneFormat
} from "../../../../../../common/Formater";

const AdminsPhones = (props) => {
    const {
        phones,
    } = props;

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.rowContent} mb={1}>
                <Typography variant="h3">Связь с администратором</Typography>

                <Tooltip title="Для того чтобы связаться с администратором позвоните на один из номеров">
                    <IconButton color="primary">
                        <HelpIcon color="primary"/>
                    </IconButton>
                </Tooltip>
            </Box>

            <Box className={classes.columnContent}>
                {phones.map((phone, index) => (
                    <Box
                        key={`admin-phone-${index}-${phone}`}
                        className={classes.rowContent}
                    >
                        <Typography variant="subtitle1">Телефон: </Typography>
                        <Link
                            className={classes.linkValue}
                            href={`tel:+${phone}`}
                        >
                            {phoneFormat(phone)}
                        </Link>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const useStyles = makeStyles({
    root: {},

    rowContent: {
        display: "flex",
        flexWrap: 'wrap',
        alignItems: "center",
        gap: 8,
    },
    columnContent: {
        maxHeight: "100px",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        gap: 8,
    },

    linkValue: {
        fontFamily: "CodeNext",
        fontFeatureSettings: "'ss03' on,'ss06' on",
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "22px",
        letterSpacing: "0.02em",
        margin: 0,
    },
});

export default AdminsPhones;