import {palette} from "../common";

export default {
    styleOverrides: {

        root: {
            fontSize: 14,
            lineHeight: "21px",
            fontWeight: "bold",
            height: 45,
            borderRadius: 12,
            boxShadow: "none",
            textTransform: "initial"
        },

        contained: {
            borderRadius: 4
        },

        outlined: {
            borderColor: palette.primary.main,
            color: palette.primary.main,
            borderRadius: 4
        },
        outlinedError: {
            borderColor: palette.error.main,
            color: palette.error.main
        },
    }
}
