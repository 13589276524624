import MuiButton from "./MuiButton";
import MuiTypography from "./MuiTypography";
import MuiTextField from "./MuiTextField";
import MuiInputBase from "./MuiInputBase";
import MuiTooltip from "./MuiTooltip";
import MuiBackdrop from "./MuiBackdrop";

import MuiPagination from "./MuiPagination";

import MuiTable from "./MuiTable";
import MuiTableHead from "./MuiTableHead";
import MuiTableBody from "./MuiTableBody";
import MuiTableRow from "./MuiTableRow";

export default {
    MuiButton,
    MuiTextField,
    MuiTypography,
    MuiInputBase,
    MuiTooltip,
    MuiBackdrop,

    MuiPagination,

    MuiTable,
    MuiTableHead,
    MuiTableBody,
    MuiTableRow
}
