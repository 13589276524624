import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { Notification, NotificationTypes } from "../../common/Notification";
import agent from "../../agent/agent";
import InputMask from "react-input-mask";
import * as Yup from "yup";

class DialogConfirmActionWithCode extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                code: "",
            },

            verificationId: null,

            onSuccess: null,
            onCancel: null,

            open: false
        };

        this.refFormik = React.createRef();
    }

    getVerificationCode = async (callback) => {
        const {
            user
        } = this.props;

        const response = await agent.post(`auth/phone-confirmation`, {
            phone: user?.phone || "",
        })
            .then(res => res.data)
            .catch(err => {
                return {
                    error: err.response,
                    status: err.response.status,
                };
            });

        if (response.error) {
            let errorMessage = response.error?.data?.message || "Возникла ошибка, попробуйте позже";

            if (response.status === 429) {
                errorMessage = 'Превышен лимит запросов, попробуйте через 5 минут';
            }

            Notification({
                message: errorMessage,
                type: NotificationTypes.error,
            });

            return null;
        }

        callback(response.confirmationId)

        Notification({
            message: "На Ваш номер скоро поступит звонок",
            type: NotificationTypes.success,
        });
    };

    open = async ({ onSuccess, onCancel }) => {

        await this.getVerificationCode((verificationId) => {
            this.setState({
                form: {
                    code: "",
                },

                verificationId,

                onSuccess: onSuccess || null,
                onCancel: onCancel || null,

                open: true
            });
        });

    };

    close = () => {
        this.setState({
            open: false
        })
    };

    onSuccess = async (form) => {
        const {
            verificationId,
        } = this.state;

        const response = await agent.post(`auth/phone-confirm`, {
            id: verificationId,
            code: form.code,
        })
            .then(res => res.data)
            .catch(err => {
                return {
                    error: err.response,
                    status: err.response.status,
                };
            });

        if (response.error) {
            let errorMessage = response.error?.data?.message || "Возникла ошибка, попробуйте позже";

            if (response.status === 429) {
                errorMessage = 'Превышен лимит запросов, попробуйте через 5 минут';
            }

            Notification({
                message: errorMessage,
                type: NotificationTypes.error,
            });

            return null;
        }

        this.onCancel();

        this.state?.onSuccess();
    };

    onCancel = () => {
        this.setState({
            open: false
        });
    };

    handleChange = ({ target }) => {
        const { name, value } = target;
        const newForm = this.refFormik.current.values;

        newForm[name] = value;

        this.refFormik.current.setValues(newForm);
    };

    render() {
        const {
            form,

            open
        } = this.state;

        return (
            <Dialog
                open={ open }
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    <Typography variant="h3">
                        Подтверждение кодом
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Formik
                        innerRef={ this.refFormik }
                        initialValues={ form }
                        validationSchema={ validationSchema }
                        onSubmit={ this.onSuccess }
                    >
                        { (props) => {
                            const {
                                values,
                                errors,
                                touched,
                                handleSubmit
                            } = props;

                            return (
                                <>
                                    <Box pt={ 1 }>
                                        <Box mb={ 2 }>
                                            <Typography variant="h6">
                                                Для подтверждения действия введите последние 4 цифры номера
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <InputMask
                                                mask="9999"
                                                value={ values.code }
                                                maskChar=" "
                                                onChange={ this.handleChange }
                                            >
                                                { () => <TextField
                                                    error={ Boolean(touched.code && errors.code) }
                                                    helperText={ touched.code && errors.code }
                                                    name="code"
                                                    label="Последние 4 номера"
                                                    placeholder="****"
                                                    variant="outlined"
                                                    fullWidth/>
                                                }
                                            </InputMask>
                                        </Box>
                                    </Box>

                                    <DialogActions>
                                        <Button
                                            variant="outlined"
                                            sx={ { borderRadius: "4px", textTransform: "initial" } }

                                            onClick={ this.onCancel }
                                        >
                                            Отменить
                                        </Button>

                                        <Button
                                            variant="contained"
                                            sx={ { borderRadius: "4px", textTransform: "initial" } }

                                            onClick={ handleSubmit }
                                        >
                                            Подтвердить
                                        </Button>
                                    </DialogActions>
                                </>
                            );
                        } }
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}

const validationSchema = Yup.object().shape({
    code: Yup.string().matches(/^[0-9]{4}$/, "Код состоих из последних 4-х цифр номера").required('Введите последние 4 цифры номера'),
});

export default compose(
    connect(
        (state) => ({
            user: state.global?.user || {},
        }),
        (dispatch) => ({}),
        null,
        { forwardRef: true },
    )
)(DialogConfirmActionWithCode);
