import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    Grid,

    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,

    TextField,
    Typography
} from "@mui/material";
import {
    convertorNumber,
    convertorToCbm,
    convertorToPiece
} from "../../../../../../helper/convertor";
import {
    Notification,
    NotificationTypes
} from "../../../../../../common/Notification";
import {
    Formik
} from "formik";
import {
    numberFormat
} from "../../../../../../common/Formater";

const DialogTransferReturnToProvider = (props) => {
    const {
        order,
        returnItems,
        isOpen,
        onClose,
        onTransfer,
    } = props;

    const refFormik = React.useRef(null);
    const initialValues = {
        deliveredProductIds: order.orderItems
            .filter(val => returnItems.find(returnItem => returnItem.productId === val.product.id))
            .map(val => {
                const returnItem = returnItems.find(returnItem => returnItem.productId === val.product.id);
                const piece = convertorToPiece(returnItem.quantity, val?.product?.pieceToCbmConversionFactor || 0);

                return {
                    ...val,
                    deliveredReturnValue: piece,
                    deliveredReturnPiece: piece,
                    deliveredReturnCbm: numberFormat(returnItem.quantity, 4, '.'),
                    notReturnPiece: 0,
                    notReturnCbm: 0,
                };
            }),
    };

    const renderQuantity = (orderItem) => {
        const piece = convertorToPiece(orderItem.quantity, orderItem?.product?.pieceToCbmConversionFactor || 0);
        return (
            <>
                <Typography>{piece} шт.</Typography>
                <Typography>{numberFormat(orderItem.quantity, 4)} м³</Typography>
            </>
        );
    };

    const onSubmit = (form) => {
        const deliveredItems = form.deliveredProductIds;
        const newForm = {
            deliveredReturnItems: deliveredItems.map(item => {
                return {
                    orderItemId: item.id,
                    quantity: item.deliveredReturnCbm,
                };
            }),
        };

        onTransfer(newForm);
        onClose();
    };

    const handleChangeInput = (e, index, product) => {
        const {value} = e.target;
        const newForm = refFormik.current.values;
        const returnItem = returnItems.find(item => item.productId === product.product.id);
        const cbm = convertorToCbm(value, product?.product?.pieceToCbmConversionFactor || 0);

        if (convertorNumber(cbm, 4, '.') <= returnItem.quantity) {
            const notReturnPiece = convertorToPiece(returnItem.quantity, product?.product?.pieceToCbmConversionFactor || 0) - value;
            const notReturnCbm = convertorToCbm(notReturnPiece, product?.product?.pieceToCbmConversionFactor || 0);
            newForm.deliveredProductIds[index].deliveredReturnValue = +value;
            newForm.deliveredProductIds[index].deliveredReturnPiece = Number(value);
            newForm.deliveredProductIds[index].deliveredReturnCbm = cbm;
            newForm.deliveredProductIds[index].notReturnPiece = notReturnPiece;
            newForm.deliveredProductIds[index].notReturnCbm = notReturnCbm;

            refFormik.current.setValues(newForm);
        } else {
            const piece = convertorToPiece(returnItem.quantity, product?.product?.pieceToCbmConversionFactor || 0);
            newForm.deliveredProductIds[index].deliveredReturnValue = piece;
            newForm.deliveredProductIds[index].deliveredReturnPiece = piece;
            newForm.deliveredProductIds[index].deliveredReturnCbm = returnItem.quantity;
            newForm.deliveredProductIds[index].notReturnPiece = 0;
            newForm.deliveredProductIds[index].notReturnCbm = 0;

            refFormik.current.setValues(newForm);

            Notification({
                message: "Введенное количество больше заявленного",
                type: NotificationTypes.error,
            });
        }
    };

    const handleCloseModal = () => {
        onClose();
    }

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="md"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">
                    {order.deliveryStatus === 'partialReturn' ? "Частичный" : "Полный"} возврат доставлен исполнителю
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box pt={1}>
                                    <Box>
                                        <Typography mb={1}>Доставленные товары возврата</Typography>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell width="40%">Наименование</TableCell>
                                                    <TableCell>Кол-во</TableCell>
                                                    <TableCell width="20%">Кол-во товаров в возврате</TableCell>
                                                    <TableCell>Кол-во невозврата</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {values.deliveredProductIds.map((val, index) => (
                                                    <TableRow key={`return-transfer-item-${val.id}`}>
                                                        <TableCell>{val.productName}</TableCell>
                                                        <TableCell>{renderQuantity(val)}</TableCell>
                                                        <TableCell>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <Grid container alignItems="flex-end" spacing={1}>
                                                                        <Grid item>
                                                                            <TextField
                                                                                sx={{
                                                                                    width: "50px",
                                                                                    height: "30px",
                                                                                    "& div": {
                                                                                        height: "30px",
                                                                                    },
                                                                                    "& input": {
                                                                                        padding: "0px 6px",
                                                                                        textAlign: "center",
                                                                                    }
                                                                                }}
                                                                                value={val.deliveredReturnPiece}
                                                                                name="returnPiece"

                                                                                onChange={(e) => handleChangeInput(e, index, val)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography
                                                                                variant="h6">шт.</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid container alignItems="flex-end" spacing={1}>
                                                                        <Grid item>
                                                                            <TextField
                                                                                sx={{
                                                                                    width: "50px",
                                                                                    height: "30px",
                                                                                    "& div": {
                                                                                        height: "30px",
                                                                                    },
                                                                                    "& input": {
                                                                                        padding: "0px 6px",
                                                                                        textAlign: "center",
                                                                                    }
                                                                                }}
                                                                                disabled
                                                                                value={val.deliveredReturnCbm}
                                                                                name="returnCbm"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography
                                                                                variant="h6">м³</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography>
                                                                {val.notReturnPiece} шт.
                                                            </Typography>
                                                            <Typography>
                                                                {val.notReturnCbm} м³
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Box>

                                <DialogActions>
                                    <Button variant="outlined" onClick={handleCloseModal}
                                            sx={{
                                                textTransform: "initial",
                                                borderRadius: "4px",
                                                padding: "4px 24px"
                                            }}>
                                        Отменить
                                    </Button>
                                    <Button variant="contained" onClick={handleSubmit}>
                                        Подтвердить
                                    </Button>
                                </DialogActions>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default DialogTransferReturnToProvider;