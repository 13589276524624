import {
    Box,
    Grid,
    Pagination,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import moment from "moment";
import React from "react";

const TableCustom = (props) => {
    const {
        data,
        filter,
        pagination,
        isLoading,
        onChangePagination,
        routeStore,
    } = props;

    const handleChangePage = (event, page) => {
        let newPagination = { ...pagination };
        newPagination.page = page;

        onChangePagination(newPagination);
    };

    const isEmpty = !data.length;

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>№ п/п</TableCell>
                        <TableCell>id склада</TableCell>
                        <TableCell>Название склада</TableCell>
                        <TableCell>Описание склада</TableCell>
                        <TableCell>Дата создания</TableCell>
                        <TableCell>Резерв</TableCell>
                        <TableCell>Адрес</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {Boolean(isLoading) ? (
                        <>
                            {[0, 1, 2, 3, 4, 5, 6].map((item) => (
                                <TableRow key={`row-item-load-${item}`}>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>
                    ) : (
                        <>
                            {isEmpty ? (
                                <TableRow>
                                    <TableCell
                                        style={{
                                            borderRadius: "0 0 10px 10px",
                                        }}
                                        align="center"
                                        colspan={10}
                                    >
                                        Ничего не найдено
                                    </TableCell>
                                </TableRow>
                            ) : (
                                data.map((row, index) => (
                                    <TableRow
                                        key={`row-item-${index}`}
                                        hover
                                        onClick={() => routeStore(row)}
                                    >
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.name || "—"}</TableCell>
                                        <TableCell>
                                            {row.description || "—"}
                                        </TableCell>
                                        <TableCell>
                                            {moment(row.createdAt).format(
                                                "DD.MM.YYYY HH:mm"
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {row.reserve ? "да" : "нет"}
                                        </TableCell>
                                        <TableCell>
                                            {row.address || "—"}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </>
                    )}
                </TableBody>
            </Table>

            <Box mt={3} />

            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Pagination
                        page={Number(pagination.page)}
                        count={Number(pagination.totalPage)}
                        onChange={handleChangePage}
                    />
                </Grid>
                <Grid item></Grid>
            </Grid>
        </>
    );
};

export default TableCustom;
