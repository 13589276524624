import React, {useState} from "react";
import {
    Box,

    Snackbar,
    Alert,
    Button,
    IconButton
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    Close as CloseIcon
} from "@mui/icons-material";


const NotificationUserNotActive = (props) => {
    const {
        user
    } = props;
    const [isOpen, setOpen] = useState(true);

    if ( user.active ) {
        return null
    }


    return (
        <Snackbar
            open={isOpen}
            onClose={() => setOpen(false)}
            message="Ожидается процесс модерации и активации аккаунта"
            ContentProps={{
                sx: {
                    backgroundColor: "#003e8a",
                    fontWeight: "600",
                    fontSize: 20
                }
            }}
            action={
                <React.Fragment>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: "12px 24px" }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </React.Fragment>
            }
        />
    )

    return (
        <Snackbar open={isOpen} onClose={() => setOpen(false)}>
            <Alert security="warning">

            </Alert>
        </Snackbar>
    )
}

const useStyles = makeStyles(() => ({

}));

export default NotificationUserNotActive
