import React from "react";
import {
    Box,
    Grid,
    Button,
    Typography
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";

const ReadyOrderInformation = (props) => {
    const {
        actions
    } = props;
    const classes = useStyles();

    return (
        <Box>

            <Box mb={2}>
                <Typography variant="h3">
                    Отгрузка
                </Typography>
            </Box>

            <Box className={classes.content}>
                {actions.map((action, index) => (
                    <Button key={`ready-button-${index}`} className={classes.actionButton} onClick={action.onClick}>
                        {action.label}
                    </Button>
                ))}
            </Box>

        </Box>
    )
}

const useStyles = makeStyles(() => ({
    content: {
        display: "flex",
        flexWrap: "wrap",

        marginLeft: -24,

        "& > *": {
            marginLeft: 24,
        }
    },

    actionButton: {
        height: 45,
        borderRadius: 100,
        backgroundColor: "white",
        border: "1px solid #84B92C",
        boxShadow: "0px 7px 14px rgba(0, 0, 0, 0.07)",
        padding: "0 24px",

        color: "#84B92C",
        fontWeight: "600",

        "&:hover": {
            backgroundColor: "#84B92C",
            color: "white"
        }
    }
}));

export default ReadyOrderInformation
