import React, { Component } from "react";
import {
    Backdrop,
    Box,
    CircularProgress,
    Typography
} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent,
    DialogCreateDriver as DialogCreateDriverComponent,
} from "./components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import {
    DialogConfirmation
} from "../../../../components";
import queryString from "query-string";
import agent from "../../../../agent/agent";

const initialFilter = {
    "sort": ""
};
const urlPage = "/drivers";

class DriversList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            isOpenCreateDriver: false,

            isLoading: true,
            isShowBackdrop: false,
        };


        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getData();
    }

    getData = async () => {
        this.setState({isLoading: true});

        const filter = this.getFilters();
        const {user} = this.props;
        const {
            drivers,
            totalCount
        } = await agent.get(`/users/drivers/${user.id}${filter}&limit=20&listing=true`).then((res) => {
            return {
                drivers: res.data.drivers || [],
                totalCount: res.data.count
            }
        }).catch((err) => {
            return {
                drivers: [],
                totalCount: 0,
            }
        });


        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(totalCount / 20) || 1;

        this.setState({
            isLoading: false,
            pagination,
            data: drivers
        });
    }

    // Создание водителя
    createDriver = async (form, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: "Вы действительно хотит создать водителя?",
                acceptButtonTitle: "Да, создать",
                cancelButtonTitle: "Отменить",
                acceptButtonAction: this.createDriver.bind(this, form, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const body = {
            firstName: form.firstName,
            lastName: form.lastName,
            username: form.username,
            email: form.email,
            phone: (form.phone || "").replace(/\D+/g, "")
        };

        const response = await agent.post(`/users`, body)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при создании водителя",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getData();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Водитель успешно создан",
            type: NotificationTypes.success,
        });
    };

    deleteDriver = async (id, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: "Вы действительно хотит удалить водителя?",
                acceptButtonTitle: "Да, удалить",
                cancelButtonTitle: "Отменить",
                acceptButtonAction: this.deleteDriver.bind(this, id, true),
            });

            return null;
        }
        this.setState({
            isShowBackdrop: true,
        })
        const response = await agent.delete(`/users/drivers`, {
            data: {
                driverId: id,
            }
        })
            .then(res => res.data)
            .catch(err => {
                return {error: err.response}
            });
        if (response.error) {
            this.setState({
                isShowBackdrop: false,
            })
            Notification({
                message: response.error.data.message || "Ошибка сервера",
                type: NotificationTypes.error,
            });
            return null;
        }
        await this.getData();
        this.setState({
            isShowBackdrop: false,
        });
        Notification({
            message: "Водитель успешно удален",
            type: NotificationTypes.success,
        });
    };

    // Логика работы с фильтром
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `${ urlPage }?${string.join("&")}`);

        return `?${string.join("&")}`
    }
    changeFilter = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getData();

    }
    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getData();
    }
    resetFilter = async () => {
        await this.setState({filter: {...initialFilter}});
        await this.getData();
    }

    // Открытие формы создания водителя
    setIsOpenCreateDriver = (isOpen) => {
        this.setState({
            isOpenCreateDriver: isOpen,
        });
    };
    // Закрытие формы создания водителя
    handleCloseCreateDriver = () => {
        this.setIsOpenCreateDriver(false);
    };

    render() {
        const {
            data,

            filter,
            pagination,

            isOpenCreateDriver,

            isLoading,
            isShowBackdrop
        } = this.state;

        return (
            <>

                <Box mb={4}>
                    <Typography variant="h1" textAlign="left">Водители</Typography>
                </Box>

                <FilterComponent
                    filter={filter}
                    onOpenCreateDriver={this.setIsOpenCreateDriver}
                />

                <TableComponent
                    data={data}
                    filter={filter}
                    pagination={pagination}

                    onChangePagination={this.changePagination}
                    onDeleteDriver={this.deleteDriver}
                />

                <DialogCreateDriverComponent
                    isOpen={isOpenCreateDriver}
                    onClose={this.handleCloseCreateDriver}
                    onCreate={this.createDriver}
                />

                <DialogConfirmation
                    ref={this.refDialogConfirmation}
                />

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="white"/>
                </Backdrop>

            </>
        );
    }
}

export default DriversList
