// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import MyOrder from './MyOrder';

export default compose(
  connect(
    state => ({
      stores: state?.stores?.stores || []
    }),
    dispatch => ({}),
  ),
)(MyOrder);
