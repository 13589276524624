import {
    Alert, Box, Button, Fade,
    Snackbar,
    Typography
} from "@mui/material";
import queryString from "query-string";
import React, { Component } from "react";
import agent from "../../../../agent/agent";
import { formatErrors } from "../../../../helper/formatErrors";
import {
    AddStoreModal as AddStoreModalCompoonent,
    Table as TableComponent
} from "./components";

const initialFilter = {
    sort: "",
};

class MyStores extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stores: [],

            filter: {
                ...initialFilter,
            },
            pagination: {
                page: 1,
                totalPage: 1,
            },
            isOpenModal: false,
            isLoading: true,
            isOpenAlert: false,
            alertMessage: "",
        };
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getStores();
    };

    closeAlert = async () => {
        await this.setState({ isOpenAlert: false });
    };

    openAlert = async (err) => {
        await this.setState({
            isOpenAlert: true,
            alertMessage:
                typeof err === "string" || Array.isArray(err)
                    ? err
                    : "Произошла ошибка при отправке формы",
        });
    };

    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket",
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch,
        };
        let pagination = {
            ...this.state.pagination,
            page: page,
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0,
        });
    };

    // Логика получения складов
    getStores = async () => {
        this.setState({ isLoading: true });

        const filter = this.getFilters();
        const { stores, totalCount } = await agent
            .get(`/stores${filter}&limit=20&listing=true`)
            .then((res) => {
                return {
                    stores: res.data.stores || [],
                    totalCount: res.data.count,
                };
            })
            .catch((err) => {
                return {
                    stores: [],
                    totalCount: 0,
                };
            });

        let pagination = { ...this.state.pagination };
        pagination.totalPage = Math.ceil(totalCount / 20) || 1;

        this.setState({
            isLoading: false,
            pagination,
            stores,
        });
    };

    //создание склада
    createStore = (onSuccess) => async (values) => {
        await agent
            .post(`/stores`, values)
            .then((res) => {
                onSuccess();
            })
            .catch((err) => {
                this.openAlert(
                    (err.response?.data?.message &&
                        Array.isArray(err.response?.data?.message) &&
                        formatErrors(err.response?.data?.message)) ||
                        (typeof err.response?.data?.message == "string" &&
                            err.response?.data?.message) ||
                        err.message ||
                        err
                );
            });
    };

    setIsOpenModal = async (isOpen) => {
        await this.setState({ isOpenModal: isOpen });
    };

    handleClose = async (thenAction) => {
        await this.setIsOpenModal(false);
        if (thenAction) {
            await this.getStores();
        }
    };

    // Логика работы с фильтром
    getFilters = () => {
        const filter = { ...this.state.filter };
        const pagination = { ...this.state.pagination };

        let string = [`page=${pagination.page}`];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(
            null,
            null,
            `/my-stores?${string.join("&")}`
        );

        return `?${string.join("&")}`;
    };
    changeFilter = async (filter, isFastStart) => {
        await this.setState({ filter });

        if (!isFastStart) {
            return null;
        }

        await this.getUsers();
    };
    changePagination = async (pagination) => {
        await this.setState({ pagination });
        await this.getStores();
    };
    resetFilter = async () => {
        await this.setState({ filter: { ...initialFilter } });
        await this.getUsers();
    };

    _routeStore = (order) => {
        this.props.history.push(`/my-stores/${order.id}`);
    };

    render() {
        const {
            stores,
            filter,
            pagination,
            isLoading,
            isOpenModal,
            isOpenAlert,
            alertMessage,
        } = this.state;

        return (
            <>
                <Box mb={4}>
                    <Typography variant="h1" textAlign="left">Мои склады</Typography>
                </Box>

                {/* <FilterComponent
                    filter={filter}
                /> */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "right",
                        marginBottom: "8px",
                    }}
                >
                    <Button
                        sx={{
                            textTransform: "initial",
                            fontSize: 12,
                            height: "auto",
                            borderRadius: 1,
                        }}
                        size="small"
                        variant="contained"
                        onClick={() => this.setIsOpenModal(true)}
                    >
                        Добавить склад
                    </Button>
                </div>
                <TableComponent
                    data={stores}
                    filter={filter}
                    pagination={pagination}
                    isLoading={isLoading}
                    onChangePagination={this.changePagination}
                    routeStore={this._routeStore}
                />
                <AddStoreModalCompoonent
                    createStore={this.createStore}
                    handleClose={(thenAction) => this.handleClose(thenAction)}
                    isOpen={isOpenModal}
                />
                <Snackbar
                    TransitionComponent={Fade}
                    open={isOpenAlert}
                    autoHideDuration={6000}
                    onClose={this.closeAlert}
                >
                    <Alert
                        onClose={this.closeAlert}
                        severity="error"
                        sx={{ width: "100%" }}
                    >
                        {Array.isArray(alertMessage)
                            ? alertMessage.map((el) => (
                                  <Typography>{el}</Typography>
                              ))
                            : alertMessage}
                    </Alert>
                </Snackbar>
            </>
        );
    }
}

export default MyStores;
