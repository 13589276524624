import React from "react";
import {
    TextField,
    Autocomplete
} from "@mui/material";
import {compose} from "recompose";
import {connect} from "react-redux";
import agent from "../../../agent/agent";

class AutocompleteTransport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            list: [],

            isLoad: false,

            updateState: 0
        };

        this.refAutocomplete = React.createRef();
    }

    componentDidMount = async () => {
        if (this.props.value) {
            await this.getOptions();
        }
    }

    getOptions = async () => {
        this.setState({ isLoad: true });

        const { depotId, driverId } = this.props;
        const search = [
            "limit=999",
            Boolean(typeof depotId !== "undefined") && `depotId=${depotId}`,
        ].filter((t) => !!t).join("&");
        const list = await agent.get(`/depots/vehicles?${ search }`).then((res) => {
            return res.data?.vehicles || []
        }).catch(() => {
            return []
        });

        this.setState({
            list,
            isLoad: false
        });
    }

    onChange = (value) => {

        if ( this.props.multiple ) {
            const parseValue = [...value].map((item) => {
                return item?.id || item
            })

            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: parseValue || []
                }
            });
        }

        if ( !this.props.multiple ) {
            this.props.onChange({
                target: {
                    name: this.props.name,
                    value: String(value?.id) || null
                },
            });
        }

    }

    _getOptionLabel = (row) => {
        const options = this.state.list;

        if (typeof row !== 'object') {
            const item = options.find((t) => String(t.id) === String(row));

            if (!item) {
                return ""
            }

            return `${item?.make} ${item?.model} (${item?.licensePlate})`
        }

        return `${row?.make} ${ row?.model } (${row?.licensePlate})`
    }
    _filterOptions = (list, params) => {
        const inputValue = params?.inputValue || "";

        return [...list].filter((t) => {

            if (!inputValue) {
                return true
            }

            const nameLowerCase = t.model.toLowerCase();
            const inputValueLowerCase = inputValue.toLowerCase();

            return Boolean(nameLowerCase.indexOf(inputValueLowerCase) > -1)

        })
    }

    render() {
        const {
            value,
            name,
            error,
            multiple
        } = this.props;
        const {
            list,
            isLoad
        } = this.state;

        return (
            <>

                <Autocomplete
                    {...this.props}

                    ref={this.refAutocomplete}
                    defaultValue={null}
                    value={value}
                    disablePortal
                    options={list}
                    onChange={(event, value) => this.onChange(value, name)}
                    name={name}
                    variant="outlined"
                    fullWidth
                    disableClearable
                    loading={isLoad}
                    onOpen={this.getOptions}
                    limitTags={1}
                    filterSelectedOptions={Boolean(multiple)}
                    autoComplete={Boolean(multiple)}

                    isOptionEqualToValue={(option, value) => Boolean(String(option.id) === String(value))}

                    getOptionLabel={this._getOptionLabel}
                    filterOptions={this._filterOptions}

                    renderInput={(params) => <TextField
                        {...params}
                        label="Автомобиль"
                        fullWidth
                        error={error}
                        helperText={this.props.helperText}
                    />}
                />

            </>
        );
    }

}

export default AutocompleteTransport
