import React, { Component } from "react";
import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import {
    AssignmentInd as AssignmentIndIcon
} from "@mui/icons-material";
import {
    Message as MessageComponent,
    AccreditationCard as AccreditationCardComponent
} from "./components";
import agent from "../../../../agent/agent";

class Accreditation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accreditations: [],
            categories: [],

            isLoading: true
        };
    }

    componentDidMount = async () => {
        await this.getAccreditations();
        await this.getCategories();
    }


    // Логика получение списка аккредитаций
    getAccreditations = async () => {
        const accreditations = await agent.get(`/accreditations`).then((res) => {
            return res.data.accreditations || []
        }).catch((err) => {
           return []
        });

        this.setState({
            accreditations: accreditations,
            isLoading: false
        })
    }

    // Логика получения списка категории
    getCategories = async () => {
        const categories = await agent.get(`/categories`).then((res) => {
            return res.data.categories || []
        }).catch(() => {
            return []
        });

        this.setState({
            categories
        })
    }

    render() {
        const {
            accreditations,
            categories,

            isLoading
        } = this.state;

        return (
            <>

                <Box mb={4}>
                    <Typography variant="h1" textAlign="left">Мои аккредитации</Typography>
                </Box>

                {Boolean(isLoading) && (
                    <MessageComponent
                        message="Загружаем список аккредитаций..."
                    />
                )}
                {Boolean(!isLoading && (accreditations.length <= 0)) && (
                    <MessageComponent
                        message="Аккредитации не найдены"
                    />
                )}

                <Grid container spacing={3}>
                    {(accreditations || []).map((accreditation, index) => (
                        <Grid key={`accreditation-${ index }`} item xs={3}>
                            <AccreditationCardComponent
                                accreditation={accreditation}
                                categories={categories}
                            />
                        </Grid>
                    ))}
                </Grid>


            </>
        );
    }
}

export default Accreditation
