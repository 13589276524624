import React, {Component} from "react";
import {
    Box,
    Divider,
    Typography,
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";
import {
    DialogConfirmation
} from "../../../../components"

import agent from "../../../../agent/agent";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import {
    InformationAddresses,
    InformationProducts,
    ActionForm
} from "./components"

class ListOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: {},

            orderId: props?.match?.params?.id || null,

            settings: {},

            isLoading: true,
            isNotFound: false,
            isShowBackdrop: false
        };

        this.refDialogConfirmation = React.createRef();
    }

    componentDidMount = async () => {

        if (!this.state.orderId) {
            this.props.history.replace(`/orders`)
        }

        await this.getSettings();

        await this.getOrder();
    }


    // Логика получения заказа
    getOrder = async () => {
        const orderId = this.state.orderId;

        const order = await agent.get(`/orders/${orderId}`).then((res) => {
            return res.data?.order || null
        }).catch(() => {
            return null
        });
        if (!order) {
            this.setState({
                isNotFound: true,
                isLoading: false
            })

            return null
        }


        await this.setState({
            isLoading: false,
            order
        });
    }

    getSettings = async () => {
        const settings = await agent.get(`/settings`)
            .then((res) => res.data.settings)
            .catch((err) => {
            });

        this.setState({settings});
    }

    // Взятие зазаказа в работу
    takeWorkOrder = async (form, isConfirm) => {
        const order = this.state.order;

        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: `Вы действительно хотите взять заказ №${order.id} в работу?`,
                acceptButtonTitle: "Да, взять",
                acceptButtonAction: this.takeWorkOrder.bind(this, form, true)
            });

            return null
        }

        this.setState({ isShowBackdrop: true })

        const storeId = this.props?.stores?.[0]?.id;
        const responsetakeWork = await agent.put(`/orders/${ order.id }/take-as-courier`, {
            ...form
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if ( responsetakeWork.error ) {
            this.setState({ isShowBackdrop: false });

            const errorMessage = Boolean(responsetakeWork.error?.status === 403) ?
                "Нет прав" :
                responsetakeWork.error?.data?.message || "Возникла ошибка, попробуйте позднее";

            Notification({
                type: NotificationTypes.error,
                message: errorMessage
            });

            return null
        }

        this.setState({ isShowBackdrop: false })

        Notification({
            type: NotificationTypes.success,
            message: `Заказ №${ order.id } взят в работу`
        });

        window.history.pushState(null, null, '/my-list');
        this.props.history.push(`/my-list/order/${ order.id }`);

    }


    render() {
        const {
            order,

            orderId,

            settings,

            isShowBackdrop,
            isLoading
        } = this.state;
        const {
            classes
        } = this.props;

        if (Object.keys(order).length <= 0) {
            return (
                <Typography variant="h3" textAlign="center">
                    Загружаем заказ
                </Typography>
            )
        }

        return (
            <>

                <Box mb={2}>
                    <Typography variant="h1">
                        Заказ №{orderId}
                    </Typography>
                </Box>

                <Box className={classes.content}>
                    {!isLoading && (
                        <InformationAddresses
                            order={order}
                            settings={settings}
                        />
                    )}

                    <Box mt={4}/>
                    <Divider sx={{margin: "0 -24px"}}/>
                    <Box mt={4}/>

                    <InformationProducts
                        products={order?.orderItems || []}
                    />

                    <Box mt={4}/>
                    <Divider sx={{margin: "0 -24px"}}/>
                    <Box mt={4}/>

                    <ActionForm
                        onTakeWork={this.takeWorkOrder}
                    />


                    <DialogConfirmation
                        ref={this.refDialogConfirmation}
                    />

                </Box>

            </>
        );
    }
}

const styles = {
    content: {
        padding: 24,
        borderRadius: 10,
        backgroundColor: "#FBFBFB"
    }
};

export default withStyles(styles)(ListOrder)
