import {
    AccountBalanceWallet as AccountBalanceWalletIcon,
    AccountBalanceWallet as WalletIcon,
    AccountCircle as AccountCircleIcon,
    Assignment as AssignmentIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    DirectionsCarFilled as DirectionsCarFilledIcon,
    FolderSpecial as FolderSpecialIcon,
    Group as GroupIcon,
    OtherHouses as OtherHousesIcon,
    Settings as SettingsIcon,
    Storage as StorageIcon,
} from "@mui/icons-material";
import { Box, Button, Drawer, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";
import { ReactSVG } from 'react-svg';
import { Link, useHistory } from "react-router-dom";
import ServiceQRCode from '../../../../assets/svg/sidebar/service-qr-code.svg';
import TelegramQRCode from '../../../../assets/svg/sidebar/telegram-qr-code.svg';

const menus = [
    {
        label: "Лента заказов",
        path: "/list",
        paths: [
            "/list"
        ],
        icon: AssignmentIcon
    },
    {
        label: "Мои заказы",
        path: "/my-list",
        paths: [
            "/my-list/order"
        ],
        icon: AssignmentTurnedInIcon
    },
    {
        label: "Базы",
        path: "/car-bases",
        icon: OtherHousesIcon
    },
    {
        label: "Транспорт",
        path: "/transport",
        icon: DirectionsCarFilledIcon
    },
    {
        label: "Водители",
        path: "/drivers",
        icon: GroupIcon
    },
    {
        label: "Кошелек",
        path: "/wallet",
        icon: WalletIcon
    },
    {
        label: "Профиль",
        path: "/profile",
        icon: AccountCircleIcon
    },
];
const menus_old = [
    {
        label: "Лента заказов",
        path: "/list",
        paths: [
            "/list"
        ],
        icon: AssignmentIcon
    },
    {
        label: "Мои заказы",
        path: "/my-list",
        paths: [
            "/my-list/order"
        ],
        icon: AssignmentTurnedInIcon
    },
    {
        label: "Кошелек",
        path: "/wallet",
        icon: AccountBalanceWalletIcon,
        disable: true
    },
    {
        label: "Система",
        path: "/system",
        icon: SettingsIcon,
        disable: true
    },
    {
        label: "Аккредитации",
        path: "/accreditation",
        icon: FolderSpecialIcon
    },
    {
        label: "Мои склады",
        path: "/my-stores",
        icon: StorageIcon
    },
    {
        label: "Профиль",
        path: "/profile",
        icon: AccountCircleIcon
    },
];

const Sidebar = () => {
    const classes = useStyles();
    const history = useHistory();

    const isActive = (path, paths) => {
        const pathSections = (history?.location?.pathname || "").split("/");
        const activePath = history?.location?.pathname;
        const activePathSections = pathSections?.slice(0, pathSections.length - 1).join("/");

        return Boolean(
            activePath === path ||
            paths.includes(activePathSections)
        );
    };

    return (
        <Drawer open={ true } variant="persistent" className={ classes.root }>
            <Box className={ classes.main }>
                <Link to="/">
                    <Typography className={ classes.mainLabel }>
                        Пилорамы онлайн
                    </Typography>
                </Link>
            </Box>

            <Box className={ classes.menu }>
                { menus.map((itemMenu, index) => (
                    <Link
                        key={ `item-menu-${ index }` }
                        className={ clsx({
                            [classes.menuItem]: true,
                            [classes.menuItemActive]: isActive(itemMenu.path, (itemMenu.paths || [])),
                            [classes.menuItemDisable]: itemMenu.disable,
                        }) }
                        to={ itemMenu.path }
                    >
                        <itemMenu.icon className={ classes.menuItemIcon }/>
                        <Typography className={ classes.menuItemLabel }>
                            { itemMenu.label }
                        </Typography>
                    </Link>
                )) }
            </Box>

            <Box className={ classes.contacts }>
                <Box mb={ 2 }>
                    <Typography className={ classes.contactLabel }>
                        QR-код на сервис
                    </Typography>
                    <ReactSVG className={ classes.qr_code } src={ ServiceQRCode }/>
                </Box>
                <Box mb={ 2 }>
                    <Typography className={ classes.contactLabel }>
                        QR-код на телеграм-бот
                    </Typography>
                    <ReactSVG className={ classes.qr_code } src={ TelegramQRCode }/>
                </Box>
                <Box>
                    <Button
                        sx={ {
                            background: "#0088cc",
                            textAlign: "left",
                            '&:hover': {
                                background: "#0078b7"
                            }
                        } }
                        target="_blank"
                        href="https://t.me/piloramiCourierBot"
                        variant="contained"
                    >
                        Перейти в telegram-бот
                        <ReactSVG src={ require("../../../../assets/svg/socials/telegram.svg").default }
                                  style={ { marginLeft: 10 } }/>
                    </Button>
                </Box>
                <Box className={ classes.contact }>
                    <Typography className={ classes.contactLabel }>
                        Адрес
                    </Typography>
                    <Typography className={ classes.contactMessage }>
                        г. Екатеринбург, ул. Луначарского, д. 130 - 53
                    </Typography>
                </Box>
                <Box className={ classes.contact }>
                    <Typography className={ classes.contactLabel }>
                        Телефон
                    </Typography>
                    <Typography className={ classes.contactMessage }>
                        +7 (904) 549-30-01
                    </Typography>
                </Box>
            </Box>
        </Drawer>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        width: 240,

        "& .MuiDrawer-paper": {
            display: "flex",
            backgroundColor: "#242424",

            width: 240,
            padding: 24,
            boxSizing: "border-box",
        },
    },

    main: {
        display: "flex",
        alignItems: "center",

        marginBottom: 32,
    },
    mainLabel: {
        fontSize: 32,
        lineHeight: "32px",
        fontWeight: "600",
        color: "white",
    },

    menu: {},
    menuItem: {
        display: "flex",
        alignItems: "center",
        position: "relative",

        padding: "12px 0",
        boxSizing: "border-box",
        borderRadius: 0,
        height: 56,

        "& > *": {
            color: "white",
        },
        "&:after": {
            position: "absolute",
            zIndex: -1,
            left: -24,
            top: 0,
            right: -24,
            bottom: 0,
            backgroundColor: "#1A1A1A",

            borderRight: "4px solid #84B92C",
        },
    },
    menuItemActive: {
        "& > *": {
            color: "#84b92c",
            fill: "#84b92c",
        },
        "& *": {
            fill: "#84b92c",
        },
        "&:after": {
            content: "''",
        },
    },
    menuItemDisable: {
        pointerEvents: "none",
        opacity: 0.4
    },
    menuItemIcon: {
        marginRight: 10,
    },
    menuItemLabel: {
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: "23px",
    },

    contacts: {
        marginTop: "auto",
    },
    contact: {
        marginTop: 8,
    },
    contactLabel: {
        fontSize: 16,
        lineHeight: "22px",
        fontWeight: "600",
        color: "white",
    },
    contactMessage: {
        fontSize: 14,
        lineHeight: "16px",
        fontWeight: "600",
        color: "white",
        opacity: 0.8,

        marginTop: 4,
    },

    qr_code: {
        maxWidth: 150,
        width: 150,
        maxHeight: 150,
        height: 150,

        "& > div": {
            maxWidth: 150,
            width: 150,
            maxHeight: 150,
            height: 150,

            "& > svg": {
                maxWidth: 150,
                width: 150,
                maxHeight: 150,
                height: 150,
            },
        },
    },
}));

export default Sidebar;
