export default {

    // User
    usersMe: "/auth/me",
    usersAuth: "users/auth",
    usersAuthConfirm: "users/auth/confirm",

    // Orders
    orderClient: "orders/client",
    orderGet: "orders"
}
