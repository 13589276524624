import React, {useRef} from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogContent,
    DialogTitle,

    Grid,

    TextField
} from "@mui/material";
import {
    Formik
} from "formik";
import {
    makeStyles
} from "@mui/styles";
import {
    AddressSuggestionsComponent
} from "../../../../../../components";
import * as Yup from "yup";

const DialogFormEditBase = (props) => {
    const {
        base,
        isOpen,
        isCreate,
        onClose,
        onEdit,
        onCreate,
    } = props;
    const refFormik = useRef(null);

    const initialValues = {
        name: '',
        description: '',
        coords: [null, null],
        address: {
            value: '',
        },
        latitude: '',
        longitude: '',
    };

    const baseValues = {...base};

    const onSubmit = (form, {resetForm}) => {
        if (isCreate) {
            const newForm = {
                ...form,
                address: form?.address?.value || "",
            };
            onCreate(newForm);
            onClose();
        } else {
            onEdit(form);
            onClose();
        }
    };

    const handleChange = ({target}) => {
        const {
            value,
            name
        } = target;
        const newForm = refFormik.current.values;
        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleChangeAddress = (value, coords) => {
        const newForm = refFormik.current.values;

        newForm.address = {...value};
        newForm.coords = coords || [null, null];
        newForm.latitude = String(coords?.[0]) || "0";
        newForm.longitude = String(coords?.[1]) || "0";

        refFormik.current.setValues(newForm);
    };

    const resetAddress = () => {
        const newForm = refFormik.current.values;

        newForm.coords = [null, null];
        newForm.latitude = '';
        newForm.longitude = '';
        newForm.address = {value: ''};

        refFormik.current.setValues(newForm);
    };

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isOpen}

            onClose={onClose}
        >
            <DialogTitle>{isCreate ? "Создание базы" : "Редактирование базы"}</DialogTitle>
            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={isCreate ? initialValues : baseValues}
                    validationSchema={isCreate ? createValidationSchema : editValidationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                {isCreate
                                    ? (
                                        <CreateForm
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            onChangeValue={handleChange}
                                            onChangeAddress={handleChangeAddress}
                                            onResetAddress={resetAddress}
                                        />
                                    )
                                    : (
                                        <EditForm
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            onChangeValue={handleChange}
                                        />
                                    )
                                }
                                <Box>
                                    <Grid container alignItems="flex-end">
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="outlined"
                                                    fullWidth
                                                    onClick={onClose}
                                                >Закрыть</Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    onClick={handleSubmit}
                                                >
                                                    {isCreate ? "Создать" : "Редактировать"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </>
                        );
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const EditForm = (props) => {
    const {
        values,
        errors,
        touched,
        onChangeValue,
    } = props;

    return (
        <Box pt={2} mb={2}>
            <Box mb={2}>
                <TextField
                    fullWidth
                    name="name"
                    value={values.name}
                    label="Название базы"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}

                    onChange={onChangeValue}
                />
            </Box>
            <Box>
                <TextField
                    multiline
                    rows={4}
                    maxRows={6}
                    fullWidth
                    name="description"
                    value={values.description}
                    label="Описание базы"
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}

                    onChange={onChangeValue}
                />
            </Box>
        </Box>
    );
};

const CreateForm = (props) => {
    const {
        values,
        errors,
        touched,
        onChangeValue,
        onChangeAddress,
        onResetAddress
    } = props;
    const classes = useStyles();

    return (
        <Box pt={2} mb={7}>
            <Box mb={2}>
                <TextField
                    fullWidth
                    name="name"
                    value={values.name}
                    label="Название базы"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}

                    onChange={onChangeValue}
                />
            </Box>
            <Box mb={2}>
                <TextField
                    multiline
                    rows={4}
                    maxRows={6}
                    fullWidth
                    name="description"
                    value={values.description}
                    label="Описание базы"
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}

                    onChange={onChangeValue}
                />
            </Box>
            <Box>
                <AddressSuggestionsComponent
                    addressValue={values.address}
                    coordsValue={values.coords}
                    latitudeValue={values.latitude}
                    longitudeValue={values.longitude}
                    touched={touched}
                    errors={errors}

                    onChangeAddress={onChangeAddress}
                    onResetAddress={onResetAddress}
                />
            </Box>
        </Box>
    );
};

Yup.addMethod(Yup.array, "tuple", function (schema) {
    if (!this.isType(schema)) Yup.ValidationError();
    return Yup.object({
        tuple: Yup.array().min(schema.length).max(schema.length), ...Object.fromEntries(Object.entries(schema)),
    }).transform((value, originalValue) => {
        if (!this.isType(originalValue)) Yup.ValidationError();
        return {
            tuple: originalValue, ...Object.fromEntries(Object.entries(originalValue)),
        };
    });
});

const editValidationSchema = Yup.object().shape({
    name: Yup.string().required('Введите название базы'),
    description: Yup.string().required('Введите описание базы'),
});

const createValidationSchema = Yup.object().shape({
    name: Yup.string().required('Введите название базы'),
    description: Yup.string().required('Введите описание базы'),
    coords: Yup.array().tuple([Yup.number()
        .required("Обязательное поле")
        .typeError("Обязательное поле")
        .min(-90, "Минимальное значение -90")
        .max(90, "Максимальное значение 90"), Yup.number()
        .required("Обязательное поле")
        .typeError("Обязательное поле")
        .min(-180, "Минимальное значение -180")
        .max(180, "Максимальное значение 180"),]),
    latitude: Yup.string().required('Введите широту').min(-90, "Минимальное значение -90").max(90, "Максимальное значение 90"),
    longitude: Yup.string().required('Введите долготу').min(-180, "Минимальное значение -180").max(180, "Максимальное значение 180"),
    address: Yup.object({
        value: Yup.string().required('Введите адрес'),
    }).required('Введите адрес'),
});

const useStyles = makeStyles({
    map: {
        height: 240
    }
})

export default DialogFormEditBase;