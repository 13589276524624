import React, { Component } from "react";
import {
    Backdrop, Box,
    CircularProgress, Typography
} from "@mui/material";
import {
    Table as TableComponent,
    Filter as FilterComponent,
    DialogForm as DialogFormComponent
} from "./components";
import {
    DialogCarDriverCommunication, DialogConfirmation, DialogExecutingOrders
} from "../../../../components";
import {
    Notification,
    NotificationTypes
} from "../../../../common/Notification";
import queryString from "query-string";
import agent from "../../../../agent/agent";

const initialFilter = {
    "sort": ""
};
const urlPage = "/transport";

class TransportList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],

            filter: {
                ...initialFilter
            },
            pagination: {
                page: 1,
                totalPage: 1
            },

            isLoading: true
        };

        this.refDialogConfirmation = React.createRef();
        this.refDialogExecutingOrders = React.createRef();
        this.refDialogForm = React.createRef();
    }

    componentDidMount = async () => {
        await this.initFilter();
        await this.getData();
    }

    getData = async () => {
        this.setState({isLoading: true});

        const filter = this.getFilters();
        const {
            vehicles,
            totalCount
        } = await agent.get(`/depots/vehicles${filter}&limit=20&listing=true`).then((res) => {
            return {
                vehicles: res.data.vehicles || [],
                totalCount: res.data.count
            }
        }).catch((err) => {
            return {
                vehicles: [],
                totalCount: 0
            }
        });


        let pagination = {...this.state.pagination};
        pagination.totalPage = Math.ceil(totalCount / 20) || 1;

        this.setState({
            isLoading: false,
            pagination,
            data: vehicles
        });
    }

    // Логика работы с фильтром
    initFilter = async () => {
        const locationSearch = this.props?.location?.search || "";
        let parseSearch = queryString.parse(locationSearch, {
            arrayFormat: "bracket"
        });
        const page = parseSearch.page || 1;

        delete parseSearch.page;

        let filter = {
            ...initialFilter,
            ...parseSearch
        };
        let pagination = {
            ...this.state.pagination,
            page: page
        };

        await this.setState({
            filter,
            pagination,

            initOpenFilter: Object.keys(parseSearch || {}).length > 0
        });
    }
    getFilters = () => {
        const filter = {...this.state.filter};
        const pagination = {...this.state.pagination};

        let string = [
            `page=${pagination.page}`
        ];
        Object.keys(filter).map((key) => {
            if (filter[key]) {
                let value = filter[key];

                string.push(`${key}=${value}`);
            }
        });

        window.history.replaceState(null, null, `${ urlPage }?${string.join("&")}`);

        return `?${string.join("&")}`
    }
    changeFilter = async (filter, isFastStart) => {

        await this.setState({filter});

        if (!isFastStart) {
            return null
        }

        await this.getData();

    }
    changePagination = async (pagination) => {
        await this.setState({pagination});
        await this.getOrders();
    }
    resetFilter = async () => {
        await this.setState({filter: {...initialFilter}});
        await this.getData();
    }

    // Логика создание транспорта
    createTransport = async (form, isCreate) => {

        if (!isCreate) {
            return this.refDialogForm.current.open({
                form: null,
                isCreate: true,
                onSubmit: this.createTransport.bind(this)
            })
        }

        this.setState({ isShowBackdrop: true });

        form.depotId = +form.depotId;
        const response = await agent.post(`/depots/vehicles`, form).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        })

        this.refDialogForm.current.close();

        await this.getData();

        this.setState({ isShowBackdrop: false });

        Notification({
            type: NotificationTypes.success,
            message: "Транспорт успешно создан"
        })

    }
    // Логика редактирование транспорта
    editTransport = async (form, isEdit) => {

        if (!isEdit) {
            return this.refDialogForm.current.open({
                form: {
                    id: form.id,
                    depotId: String(form.depotId),
                    make: form.make,
                    model: form.model,
                    licensePlate: form.licensePlate,
                },
                isCreate: false,
                onSubmit: this.editTransport.bind(this)
            })
        }

        this.setState({ isShowBackdrop: true });

        const formId = form.id
        form.depotId = +form.depotId;
        delete form.id;
        const response = await agent.put(`/depots/vehicles/${ formId }`, form).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        })

        this.refDialogForm.current.close();

        await this.getData();

        this.setState({ isShowBackdrop: false });

        Notification({
            type: NotificationTypes.success,
            message: "Транспорт успешно изменен"
        })

    }
    // Логика удаления транспорта
    deleteTransport = async (transportId, isConfirm) => {
        if (!isConfirm) {
            this.refDialogConfirmation.current.onOpen({
                title: "Подтверждение",
                message: "Вы действительно хотите удалить транспорт?",
                acceptButtonTitle: "Да, удалить",
                cancelButtonTitle: "Отменить",
                acceptButtonAction: this.deleteTransport.bind(this, transportId, true),
            });

            return null;
        }

        this.setState({isShowBackdrop: true});

        const response = await agent.delete(`/depots/vehicles/${transportId}`)
            .then(res => res.data)
            .catch(err => {
                return {error: err.response};
            });

        if (response.error) {
            this.setState({isShowBackdrop: false});

            Notification({
                message: response.error?.data?.message || "Ошибка при удалении транспорта",
                type: NotificationTypes.error,
            });

            return null;
        }

        if (!response.success) {
            this.setState({isShowBackdrop: false});

            this.refDialogExecutingOrders.current.open({
                orders: response?.orders || [],
            });

            Notification({
                message: "Транспорт нельзя удалить, на транспорте есть заказы",
                type: NotificationTypes.error,
            });

            return null;
        }

        await this.getData();

        this.setState({isShowBackdrop: false});

        Notification({
            message: "Транспорт успешно удален",
            type: NotificationTypes.success,
        });
    };

    render() {
        const {
            data,

            filter,
            pagination,

            isLoading,
            isShowBackdrop
        } = this.state;

        return (
            <>

                <Box mb={4}>
                    <Typography variant="h1" textAlign="left">Транспорт</Typography>
                </Box>

                <FilterComponent
                    filter={filter}

                    onCreate={this.createTransport}
                />

                <TableComponent
                    data={data}
                    filter={filter}
                    pagination={pagination}

                    isLoading={isLoading}

                    onChangePagination={this.changePagination}
                    onEditTransport={this.editTransport}
                    onDeleteTransport={this.deleteTransport}
                />

                <DialogFormComponent
                    ref={this.refDialogForm}
                />

                <DialogConfirmation ref={this.refDialogConfirmation}/>

                <DialogExecutingOrders ref={this.refDialogExecutingOrders}/>

                <Backdrop open={isShowBackdrop}>
                    <CircularProgress color="white"/>
                </Backdrop>
            </>
        );
    }
}

export default TransportList
