import React, {useRef} from 'react';
import {Map as YMap} from "@pbe/react-yandex-maps";

const MapWithRoute = (props) => {
    const {
        pointA,
        pointB,
        center
    } = props;
    const map = useRef(null);

    const addRoute = (ymaps) => {

        const multiRoute = new ymaps.multiRouter.MultiRoute(
            {
                referencePoints: [pointA, pointB],
                params: {
                    routingMode: "auto",
                }
            },
            {
                boundsAutoApply: true
            }
        );

        map.current.geoObjects.add(multiRoute);
    };

    return (
        <YMap
            modules={["multiRouter.MultiRoute"]}
            defaultState={{
                center: center || [56.838011, 60.597465],
                zoom: 15,
                controls: ['zoomControl'],
            }}
            style={{
                width: "100%",
                height: "100%",
                borderRadius: 8
            }}
            instanceRef={map}
            onLoad={addRoute}
        ></YMap>
    );
};

export default MapWithRoute;
