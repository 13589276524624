import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Typography,
    Button,
    Grid,
    Box,

    Divider
} from "@mui/material";
import {

} from "@mui/styles";
import {
    Notification,
    NotificationTypes
} from "../../common/Notification";
import agent from "../../agent/agent";

import TableDriver from "./TableDriver.js";
import TableTransport from "./TableTransport.js";
import FormDriver from "./FormDriver.js";
import FormTransport from "./FormTransport.js";

class DialogCarDriverCommunication extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            dataDrivers: [],
            dataVehicles: [],

            id: null,
            form: null,

            isLoad: false,
            isOpen: false,
            isCreated: false
        }
    }

    open = ({ id, form }) => {
        this.setState({
            id,
            form,
            isOpen: true
        },  async () => {

            if ( this.props.type === "drivers" ) {
                await this.getListDrivers();
            }
            if ( this.props.type === "transport" ) {
                await this.getListVehicles();
            }

        })
    }
    close = () => {
        this.setState({
            isOpen: false,

            dataVehicles: [],
            dataDrivers: [],
        })
    }

    // Получение списка пар
    getListDrivers = async () => {
        const data = await agent.get(`/depots/vehicles?limit=999&driverId=${this.state.id}`).then((res) => {
            return res.data?.vehicles || ""
        }).catch((err) => {
            return []
        })

        this.setState({
            dataVehicles: data
        })
    }
    getListVehicles = async () => {
        const data = await agent.get(`/depots/drivers?limit=999&vehicleId=${this.state.id}`).then((res) => {
            return res.data?.drivers || ""
        }).catch((err) => {
            return []
        })

        this.setState({
            dataDrivers: data
        })
    }

    // Логика создания пары
    createPairDrivers = async (transportId) => {

        this.setState({ isCreated: true })

        const response = await agent.post(`/depots/vehicle-drivers`, {
            vehicleId: Number(transportId),
            driverId: Number(this.state.id)
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (response.error) {
            this.setState({ isCreated: false })

            Notification({
                type: NotificationTypes.error,
                message: "Ошибка создания пары"
            });

            return
        }

        this.setState({ isCreated: false })

        Notification({
            type: NotificationTypes.success,
            message: "Пара успешно создана"
        });

        await this.getListVehicles();
    }
    createPairVehicles = async (userId) => {

        this.setState({ isCreated: true })

        const response = await agent.post(`/depots/vehicle-drivers`, {
            vehicleId: Number(this.state.id),
            driverId: Number(userId)
        }).then((res) => {
            return res.data
        }).catch((err) => {
            return { error: err.response }
        });
        if (response.error) {
            this.setState({ isCreated: false })

            Notification({
                type: NotificationTypes.error,
                message: "Ошибка создания пары"
            });

            return
        }

        this.setState({ isCreated: false })

        Notification({
            type: NotificationTypes.success,
            message: "Пара успешно создана"
        });

        await this.getListVehicles();
    }

    _renderTable = () => {
        if (this.props.type === "drivers") {
            return (
                <TableTransport
                    data={this.state.dataVehicles}
                />
            )
        }
        if (this.props.type === "transport") {
            return (
                <TableDriver
                    data={this.state.dataDrivers}
                />
            )
        }
    }
    _renderForm = () => {
        if (this.props.type === "drivers") {
            return (
                <FormTransport
                    isCreated={this.state.isCreated}
                    onSubmit={this.createPairDrivers}
                />
            )
        }
        if (this.props.type === "transport") {
            return (
                <FormDriver
                    isCreated={this.state.isCreated}
                    onSubmit={this.createPairVehicles}
                />
            )
        }
    }

    _dialogTitle = () => {
        if (this.props.type === "drivers") {
            return "Связь ВОДИТЕЛЬ - АВТОМОБИЛЬ"
        }
        if (this.props.type === "transport") {
            return "Связь АВТОМОБИЛЬ - ВОДИТЕЛЬ"
        }
    }

    render() {
        const {
            isOpen
        } = this.state

        return (
            <Dialog
                open={isOpen}

                fullWidth
                maxWidth="md"

                onClose={this.close}
            >

                <DialogTitle>
                    <Typography variant="h3">
                        {this._dialogTitle()}
                    </Typography>
                </DialogTitle>

                <DialogContent>

                    <Box>
                        { this._renderTable() }
                    </Box>

                    <Box my={4}>
                        <Divider/>
                    </Box>

                    <Box>
                        { this._renderForm() }
                    </Box>

                </DialogContent>

                <DialogActions></DialogActions>

            </Dialog>
        );
    }
}

export default DialogCarDriverCommunication
