import React from 'react';
import {
    Box,

    Dialog,
    DialogContent,
    DialogTitle,

    Link,

    Typography
} from "@mui/material";
import {
    withStyles
} from "@mui/styles";

class DialogExecutingOrders extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            executingOrders: [],

            isOpen: false,
        };
    }

    open = ({orders}) => {
        const executingOrders = orders.map(order => order.id);

        this.setState({
            executingOrders,
            isOpen: true,
        });
    };

    close = () => {
        this.setState({
            isOpen: false,
        }, () => {
            setTimeout(() => {
                this.setState({executingOrders: [],});
            }, 500);
        });
    };

    render() {
        const {
            classes,
        } = this.props;

        const {
            executingOrders,
            isOpen,
        } = this.state;
        return (
            <Dialog
                open={isOpen}
                fullWidth
                maxWidth="md"
                onClose={this.close}
            >
                <DialogTitle>
                    <Typography variant="h3">
                        Выполняющиеся заказы на складе
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h4" mb={1}>
                        Заказы:
                    </Typography>
                    <Box className={classes.rowContent}>
                        {executingOrders.map(order => (
                            <Link
                                className={classes.link}
                                key={`executing-order-${order}`}
                                href={`/my-list/order/${order}`}
                                target="_blank"
                            >
                                №{order}
                            </Link>
                        ))}
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }
}

const styles = {
    rowContent: {
        width: "100%",
        height: "100%",
        maxHeight: "600px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        gap: 16,
    },

    link: {
        display: "block",
        width: "max-content",
    },
};

export default withStyles(styles)(DialogExecutingOrders);