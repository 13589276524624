const UPDATE_USER = 'app/UPDATE_USER'

const initialState = {
    user: null,
};

export function setUser(user) {
    return {
        type: UPDATE_USER,
        user
    }
}

export default function AppState(state = initialState, action = {}) {
    switch (action.type) {
        case UPDATE_USER: {
            let user = action.user

            return {
                ...state,
                user
            }
        }
        default:
            return state;
    }
}
