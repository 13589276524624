import React from 'react';
import {
    Box,
    Button,

    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,

    TextField,
    Typography
} from "@mui/material";
import {
    Formik
} from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";

const DialogTransferTheGoodsConfirm = (props) => {
    const {
        isOpen,
        onClose,
        onConfirm,
    } = props;

    const refFormik = React.useRef(null);
    const [initialValues, setInitialValues] = React.useState({
        code: '',
    });

    React.useEffect(() => {
        if (isOpen) {
            setInitialValues({
                code: '',
            });
        }
    }, [isOpen]);


    const onSubmit = (form) => {
        onConfirm(form.code);
        handleCloseModal();
    };

    const handleChange = ({target}) => {
        const {value, name} = target;
        const newForm = refFormik.current.values;

        newForm[name] = value;

        refFormik.current.setValues(newForm);
    };

    const handleCloseModal = () => {
        onClose();
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="sm"
            onClose={handleCloseModal}
        >
            <DialogTitle>
                <Typography variant="h3">
                    Подтверждение принятия груза
                </Typography>
            </DialogTitle>

            <DialogContent>
                <Formik
                    innerRef={refFormik}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {(props) => {
                        const {
                            values,
                            errors,
                            touched,
                            handleSubmit
                        } = props;

                        return (
                            <>
                                <Box mt={1}>
                                    <InputMask
                                        mask="999999"
                                        value={values.code}
                                        maskChar=""
                                        onChange={handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                value={values.code}
                                                error={Boolean(touched.code && errors.code)}
                                                helperText={touched.code && errors.code}
                                                name="code"
                                                label="Код от исполнителя"
                                                placeholder="000000"
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                    </InputMask>
                                </Box>
                                <DialogActions>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            borderRadius: "5px",
                                            textTransform: "none",
                                        }}

                                        onClick={handleCloseModal}
                                    >
                                        Отменить
                                    </Button>

                                    <Button
                                        variant="contained"
                                        sx={{
                                            borderRadius: "5px",
                                            textTransform: "none",
                                        }}

                                        onClick={handleSubmit}
                                    >
                                        Подтвердить
                                    </Button>
                                </DialogActions>
                            </>
                        )
                    }}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

const validationSchema = Yup.object().shape({
    code: Yup.string()
        .matches(/^[0-9]{6}$/, "Код состоит из 6-ти символов")
        .required('Введите код, который Вам сообщит исполнитель'),
});

export default DialogTransferTheGoodsConfirm;