// @flow
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Profile from './Profile';
import {
    setUser
} from "../../../../states/global";

export default compose(
  connect(
    state => ({
        user: state.global?.user || {}
    }),
    dispatch => ({
        setUser: (user) => dispatch(setUser(user))
    }),
  ),
)(Profile);
