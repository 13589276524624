import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Typography,
    Button,
    Grid,
    Box, TextField
} from "@mui/material";
import {

} from "@mui/styles";
import {
    Formik
} from "formik";
import {
    AutocompleteDepots
} from "../../../../../../components";
import * as Yup from "yup";

const initForm = {
    "depotId": undefined,
    "make": "",
    "model": "",
    "licensePlate": "",
};

class DialogForm extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            form: {...initForm},

            isOpen: false,
            isCreate: false,

            onSubmit: null
        }

        this.refFormik = React.createRef();
    }

    open = ({ form, onSubmit, isCreate }) => {
        this.setState({
            onSubmit,
            isCreate,
            isOpen: true
        },  async () => {

            if (isCreate) {
                return
            }

            await setTimeout(() => {}, 500);

            this.refFormik.current.setValues(form)
        })
    }
    close = () => {
        this.setState({
            isOpen: false
        })
    }

    // Логика работы с формой
    onSubmit = (form) => {
        this.state.onSubmit(form, true)
    }
    changeForm = ({ target }) => {
        const {
            name,
            value
        } = target;

        let newForm = {...this.refFormik.current.values};
        newForm[name] = value;
        this.refFormik.current.setValues(newForm);
    }

    // Вспомогательные функции
    _dialogTitle = () => {
        if (this.state.isCreate) {
            return "Создание транспорта"
        }

        return `Редактирование транспорта №`
    }

    render() {
        const {
            form,

            isOpen,
            isCreate
        } = this.state;

        return (
            <Dialog
                open={isOpen}
                fullWidth

                maxWidth="md"

                onClose={this.close}
            >

                <DialogTitle>
                    <Typography variant="h3">
                        {this._dialogTitle()}
                    </Typography>
                </DialogTitle>

                <DialogContent>

                    <Formik
                        innerRef={this.refFormik}
                        initialValues={form}
                        validationSchema={validationSchema}
                        onSubmit={this.onSubmit}
                    >
                        {(props) => {
                            const {
                                values,
                                errors,
                                touched,
                                handleSubmit,
                            } = props;

                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    onKeyDown={({keyCode}) => (keyCode === 13) && handleSubmit()}
                                >

                                    <Box mt={1}>
                                        {Boolean(isCreate || typeof values.depotId !== "undefined") && (
                                            <AutocompleteDepots
                                                error={Boolean(touched.depotId && errors.depotId)}
                                                helperText={touched.depotId && errors.depotId}
                                                value={values.depotId}
                                                name="depotId"
                                                onChange={this.changeForm}
                                            />
                                        )}
                                    </Box>

                                    <Box mt={2}>
                                        <TextField
                                            value={values.make}
                                            error={Boolean(touched.make && errors.make)}
                                            helperText={touched.make && errors.make}
                                            name="make"
                                            label="Марка ТС"
                                            placeholder="Введите"
                                            variant="outlined"
                                            fullWidth
                                            onChange={this.changeForm}
                                        />
                                    </Box>

                                    <Box mt={2}>
                                        <TextField
                                            value={values.model}
                                            error={Boolean(touched.model && errors.model)}
                                            helperText={touched.model && errors.model}
                                            name="model"
                                            label="Модель ТС"
                                            placeholder="Введите"
                                            variant="outlined"
                                            fullWidth
                                            onChange={this.changeForm}
                                        />
                                    </Box>

                                    <Box mt={2}>
                                        <TextField
                                            value={values.licensePlate}
                                            error={Boolean(touched.licensePlate && errors.licensePlate)}
                                            helperText={touched.licensePlate && errors.licensePlate}
                                            name="licensePlate"
                                            label="Гос. знак ТС"
                                            placeholder="Введите"
                                            variant="outlined"
                                            fullWidth
                                            onChange={this.changeForm}
                                        />
                                    </Box>

                                    <Box mt={2}>
                                        <Grid container alignItems="flex-end">
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant="outlined"
                                                        fullWidth
                                                        onClick={this.close}
                                                    >Закрыть</Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant="contained"
                                                        fullWidth
                                                        onClick={handleSubmit}
                                                    >
                                                        {isCreate ? "Создать" : "Редактировать"}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </form>
                            )
                        }}
                    </Formik>

                </DialogContent>

            </Dialog>
        );
    }
}

const validationSchema = Yup.object().shape({
    depotId: Yup.string().required('Выберите базу').nullable(),
    make: Yup.string().required('Введите марку ТС'),
    model: Yup.string().required('Введите модель ТС'),
    licensePlate: Yup.string().required('Введите Гос. знак ТС'),
});

export default DialogForm
